<template>
  <section v-if="slice && slice.primary" class="story-banner" :style="getBGImage(slice.primary.banner_image)">
    <div class="overlay" />
    <b-container class="story-banner__typography-container">
      <b-row>
        <b-col>
          <div class="story-banner__typography-container__content">
            <prismic-rich-text :field="slice.primary.banner_title" />
            <prismic-rich-text :field="slice.primary.subline" />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div id="content-main"></div>
  </section>
</template>

<script>
export default {
  name: "story-hero",
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
    sliceIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    /** Get the banner background image */
    getBGImage(item) {
      return {
        backgroundImage: 'url("' + item.url + '")',
      };
    }
  },
};
</script>

<style lang="scss">
.story-banner {
  height: auto;
  width: 100%;
  background-position: center center;
  background-size: cover;
  margin-bottom: 55px;
  display: flex;
  align-items: flex-end;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 15%,
        rgba(0, 0, 0, 0.8) 90%);
    z-index: 0;

    @include respond-below(sm) {
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(40, 40, 40, 0.4822303921568627) 30%,
          rgba(117, 117, 117, 0) 75%,
          rgba(0, 0, 0, 0.5998774509803921) 100%);
    }
  }

  &__typography-container {
    text-align: left;
    position: relative;
    z-index: 1;

    &__content {
      margin-top: 48vh;
      margin-bottom: 50px;
      max-width: 70%;
      width: 100%;

      h1 {
        color: #fff;
        margin-bottom: 30px;
        text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
      }

      h6,p {
        font-family: "Arial";
        font-size: 22px;
        line-height: 26px;
        color: #ffffff;
        text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        margin: 0;
      }

      @include respond-below(xl) {
        margin-bottom: 134px;
        max-width: 70%;
      }

      @include respond-below(xsl) {
        max-width: 100%;
      }

      @include respond-below(ipad) {
        h1 {
          font-size: 50px;
          line-height: 50px;
          margin-bottom: 15px;
        }
      }

      @include respond-below(sm) {
        max-width: 100%;
        margin: auto;
        margin-bottom: 30px;

        h1 {
          font-size: 33px;
          line-height: 50px;
        }
      }
    }
  }

  @include respond-below(xl) {
    height: 892px;
  }

  @include respond-below(ipad-pro) {
    height: 75vh;
    margin-bottom: 30px;
  }
}
</style>
