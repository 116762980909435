<template>
  <div>
    <button
        class="emoji"
        v-for="score in [1, 2, 3, 4]"
        :key="score"
        :class="{ 'emoji--scored': rating == score }"
        :disabled="disabled"
        @click="submitRating(score)"
    >
      <img :src="require(`@/assets/img/emoji-${score}.jpg`)" :alt="'emoji-' + score">
      <span>Lorem</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['uid', 'title'],
  data() {
    return {
      rating: null, // Store the user's rating
      disabled: false, // Disable buttons after a rating has been submitted
    };
  },
  mounted() {
    this.checkRatingStatus();
  },
  methods: {
    async submitRating(score) {
      const url = window.location.href.replace(window.location.origin, '');
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/satisfaction-score/api/score`, {
          title: this.title,
          url: url,
          uid: this.uid,
          rating: score.toString(),
        });
        this.rating = score;
        this.disabled = true;
        this.setRatingCookie(score);
      } catch (error) {
        console.error('Error submitting rating:', error);
      }
    },
    setRatingCookie(score) {
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + (365 * 24 * 60 * 60 * 1000)); // Cookie expires in 1 year
      document.cookie = `ratedContent_${this.uid}=${score}; expires=${expiryDate.toUTCString()}; path=/`;
    },
    checkRatingStatus() {
      const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(`ratedContent_${this.uid}=`))
          ?.split('=')[1];
      if (cookieValue !== undefined) {
        this.rating = parseInt(cookieValue, 10);
        this.disabled = true; // Disable buttons if a rating has already been submitted
      }
    }
  },
};
</script>

<style lang="scss">
.emoji{
  border: none;
  background: none;
  outline: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  transition: .3s ease-in-out;
  margin-right: 40px;
  margin-bottom: 1rem;
  pointer-events: all;
  cursor: pointer;
  
  img{
    width: auto;
    height: 150px;
    margin-bottom: 1rem;
    transition: .3s ease-in-out;

    @include respond-below(md) {
      height: 80px;
      margin: 0;
    }

    @include respond-below(sm) {
      height: 50px;
      margin: 0;
    }
  }

  &:disabled  {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
  }

  &--scored{
    opacity: 1 !important;
  }

  span{
    font-size: 28px;
    color: #000000;
    transition: .3s ease-in-out;

    @include respond-below(md) {
      font-size: 22px;
    }

    @include respond-below(md) {
      font-size: 16px;
    }
  }

  @include respond-below(sm) {
    margin-right: 20px;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
</style>
