<template>
    <section class="story-emojis">
        <b-container>
            <b-col cols="12" xl="6">
                <prismic-rich-text :field="slice.primary.heading" />
            </b-col>
            <b-col cols="12">
                <satisfaction-survey :uid="uid" :title="bannerHeading[0].text" />
            </b-col>
        </b-container>
    </section>
</template>

<script>
import SatisfactionSurvey from "@/components/SatisfactionSurvey.vue";
export default {
    name: "story-emojis",
    components: {
        SatisfactionSurvey
    },
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        },
        bannerHeading: {
            type: Array,
            default() {
                return null;
            },
        },
        uid: {
            type: String,
            default() {
                return null;
            },
        }
    }
};
</script>

<style lang="scss">
.story-emojis {
    margin-bottom: 80px;

    h3 {
        margin-bottom: 30px;

        br {
            display: none;
        }
    }

    @include respond-below(sm) {
        margin-bottom: 40px;

        h3 {
            margin-bottom: 20px;
            font-size: 24px;
        }
    }
}
</style>