<template>
  <section class="sitemap-sitemap-list">
    <b-container>
      <b-row>
        <b-col class="p-0">
          <div class="sitemap-sitemap-list__sitemap-list rich-text">
            <h1 class="no-dot"><strong>Sitemap</strong></h1>
            <template v-for="item in slice.items">
              <template v-for="list in item.list">
                <b-link
                    :key="list.text"
                    :class="[item.list_level]"
                    :to="globalMakeLink(list.spans[0].data)"
                >{{ list.text }}
                </b-link
                >
              </template>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "SiteMapList",
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="scss">
.sitemap-sitemap-list {
  margin-bottom: 180px;

  h1 {
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 55px;

    @include respond-below(xl) {
      font-size: 35px;
      line-height: 43px;
    }
  }

  &__sitemap-list {
    display: flex;
    flex-flow: column;

    ul li {
      font-weight: 300;
    }

    a {
      display: inline-block;
      font-size: 22px;
      line-height: 30px;
      position: relative;
      color: #000;
      transition: 0.2s ease-in-out;
      text-decoration: underline !important;

      &::before {
        content: "";
        position: relative;
        width: 7px;
        height: 7px;
        background: #4a4a4a;
        display: inline-block;
        border-radius: 50%;
        top: -6px;
        margin-right: 8px;
      }

      &.no-dot {
        &::before {
          display: none;
        }
      }

      &.no-link {
        pointer-events: none;
      }

      &.first {
        margin-left: 45px;
      }

      &.second {
        margin-left: 75px;
      }

      &.third {
        margin-left: 105px;
      }

      @include respond-below(sm) {
        font-size: 16px;
        line-height: 20px;

        &::before {
          top: -3px;
        }

        &.first {
          margin-left: 25px;
        }
        &.second {
          margin-left: 55px;
        }
        &.third {
          margin-left: 85px;
        }
      }
    }
  }

  @include respond-below(sm) {
    margin-bottom: 65px;
  }
}
</style>
