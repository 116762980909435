<template>
  <section class="category-page__sections">
    <b-container>
      <b-row>
        <b-col class="category-page__sections__results" md="12">
          <div class="inner">
            <figure class="image">
              <prismic-image v-if="getImage(document) != {}" :field="getImage(document)"/>
            </figure>
            <div class="content">
              <b-link
                  tabindex="0"
                  :to="'/our-values/' + document.data.category.toLowerCase()"
                  v-if="document.data.category"
                  class="taglink pe-none"
                  :class="'taglink--' + document.data.category.toLowerCase()"
                  :style="`background-color: ${getCategoryColour(document.data.category)}`">
                <span :style="`color: ${getCategoryTextColour(document.data.category)}`">
                {{ document.data.category }}
                </span>
              </b-link>
              <b-link :to="linkResolver(document)" v-if="document.data.title">
                <h2>{{ document.data.title[0].text }}</h2>
              </b-link>
              <p  v-if="document.data.subline">{{ document.data.subline[0].text }}</p>
              <b-link class="btn" :to="linkResolver(document)"> {{ getLabel('read_more_text') }}</b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import linkResolver from "@/prismic/link-resolver";

export default {
  name: "CategoryRegionRegion",
  props: {
    document: {
      type: Object,
      default() {
        return null;
      },
    },
    index: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  methods: {
    linkResolver,
    getImage(document) {
      try {
        if (document.type === 'issue' && document.data.body[0].items[0].image) {
          return document.data.body[0].items[0].image;
        }
        if (document.type === 'past_issue') {
          return document.data.image;
        }
        if (document.data.main_image) {
          return document.data.main_image
        }
        if (document.type === 'news_item' && document.data.gallery && document.data.gallery.length) {
          return document.data.gallery[0].image;
        }
      } catch (ex) {
        console.error('Error getting issue image', ex)
      }
      return {};
    }
  },
};
</script>

<style lang="scss">
.category-page__sections {
  &__results {
    margin-bottom: 100px;

    .inner {
      display: flex;
      position: relative;
      overflow: hidden;
      gap: 60px;

      .image {
        flex-basis: 50%;
        min-height: 500px;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        flex-basis: 50%;
        padding-left: 58px;
        border-left: 1px solid #4A4A4A;

        h2 {
          font-size: 48px;
          line-height: 1;
          margin-bottom: 2rem;
        }

        p {
          font-size: 28px;
          line-height: 35px;
          margin-bottom: 2rem;
        }

        a.btn {
          background: #1D7880;
          padding: 1rem 2rem;
          border-radius: 50px;
          font-size: 22px;
          line-height: 1;
          color: #ffffff;
          transition: 0.2s ease-in-out;

          &:hover {
            background: #F9B000;
            color: #000000;
          }
        }
      }

      @include respond-below(md) {
        flex-wrap: wrap;

        .image,
        .content {
          flex-basis: 100%;
        }

        .image {
          min-height: auto;
        }

        .content {
          h2 {
            font-size: 28px;
            line-height: 1;
            margin-bottom: 1rem;
          }

          p {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 1rem;
          }
        }
      }

      @include respond-below(sm) {
        gap: 20px;

        .content {
          padding-left: 0;
          border: none;
          border-top: 1px solid #4A4A4A;
          padding-top: 20px;

          p {
            font-size: 16px;
            line-height: 20px;
          }

          a.btn {
            font-size: 16px;
            line-height: 1;
            padding: 1rem;
          }
        }
      }
    }

    @include respond-below(sm) {
      margin-bottom: 50px;
    }
  }
}
</style>
