<template>
  <section class="story-cta" v-if="slice.primary.cta_title">
    <b-container>
      <b-row>
        <b-col>
          <div class="story-cta__main">
            <h2>{{ slice.primary.cta_title }}</h2>
            <a v-if="slice.primary.button_link && isEmailLink(slice.primary.button_link)" href="#"
               @click.prevent="$bvModal.show('contact-modal')">
              <img src="../../../assets/img/email-icon.svg" alt="">
              <span>{{ slice.primary.button_title }}</span>
            </a>
            <a v-else-if="slice.primary.button_link" :href="slice.primary.button_link">
              <img src="../../../assets/img/website-icon.svg" alt="">
              <span>{{ slice.primary.button_title }}</span>
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "story-cta",
  components: {ContactForm},
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
    sliceIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
};
</script>

<style lang="scss">
.story-cta {
  margin-bottom: 80px;

  &__main {
    background: #F9B000;
    padding: 40px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p, h2 {
      margin: 0;
      font-size: 29px;
      line-height: 35px;
      color: #3C3C3C;
      font-family: 'ArialBold';

      @include respond-below(sm) {
        font-size: 16px;
        line-height: 22px;
        padding-right: 5px;
      }
    }

    a {
      background: #1D7880;
      padding: 1rem 2rem;
      border-radius: 50px;
      font-size: 29px;
      line-height: 1;
      color: #ffffff;
      transition: .2s ease-in-out;
      white-space: nowrap;

      img {
        width: 54px;
        margin-right: 25px;
      }

      &:hover {
        color: #ffffff;
        -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.50);
        -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.50);
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.50);
      }
    }

    @include respond-below(md) {
      padding: 20px;

      p {
        font-size: 22px;
        line-height: 28px;
        flex: 1;
        padding-right: 1rem;
      }

      a {
        padding: 10px 20px;
        font-size: 24px;

        img {
          vertical-align: baseline;
          width: 26px;
          margin-right: 10px;
        }
      }
    }

    @include respond-below(sm) {

      p {
        font-size: 18px;
        line-height: 22px;
      }

      a {
        font-size: 20px;
        display: flex;
        align-items: center;

        img {
          width: 24px;
        }
      }
    }
  }

  @include respond-below(md) {
    margin-bottom: 40px;
  }
}
</style>
