<template>
  <div v-if="!isSvg">

    <img
      v-if="this.size === null && lazyLoad"
      v-lazy="{
        src: imageUrl,
        loading: tinyImageUrl
      }"
      :alt="alt ?  alt : (image.alt ? image.alt : '')"
      :class="mainImageClass"
    >
    <img
      v-if="this.size === null && !lazyLoad"
      :src="image.url"
      :alt="alt ?  alt : (image.alt ? image.alt : '')"
      :class="mainImageClass"
    >
    <img
      v-else-if="image[size]"
      :src="image[size].url"
      :alt="alt ?  alt : (image[size].alt ? image[size].alt : '')"
      :class="mainImageClass"
    >
    <img
      v-if="image.mobile"
      :src="image.mobile.url"
      :alt="alt ?  alt : (image.mobile.alt ? image.mobile.alt : '')"
      :class="mobileImageClass"
    >
  </div>
  <div v-else>
    <inline-svg :src="image.url"/>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'PrismicImage',
  components : {
    InlineSvg
  },
  props: {
    /**
     * Should the iamge crop setting from the CMS be preserved?
     */
    preserveCmsCrop:{
      type: Boolean,
      default () {
        return true
      }
    },
    /**
     * To lazy load or not?
     */
    lazyLoad: {
      type: Boolean,
      default () {
        return true
      }
    },
    /**
     * The image prop is a prismic image object passed directly from prismic
     */
    image: {
      type: Object,
      default () {
        return {
          dimensions: {
            width: 0,
            height: 0
          },
          alt: null,
          copyright: null,
          url: null
        }
      }
    },
    /**
     * The image size to render (e.g. featured)
     */
    size: {
      type: String,
      default () {
        return null
      }
    },
    /**
     * If alt is provided it overides or set prismic image alt
     */
    alt: {
      type: String,
      default () {
        return null
      }
    },
    IEfix: {
      type: Boolean,
      default () {
        return false
      }
    },
  },
  computed: {
    imageUrl() {
      let imageUrl = ''
      if(!this.preserveCmsCrop) {
        imageUrl = this.getImageUrl(this.image, this.image.dimensions.width, this.image.dimensions.height)
      } else {
        imageUrl = this.image.url
      }
      return imageUrl
    },
    tinyImageUrl () {
      if (!this.image || !this.image.url) {
        console.error('Image URL is undefined')
        return null
      }

      const imageUrl = this.image.url
      try {
        let tinyImageUrl = imageUrl.substring(0, imageUrl.indexOf('?'))
        tinyImageUrl += '?auto=compress,format&w=50&h=50'
        return tinyImageUrl
      } catch (ex) {
        console.error('Error getting tinyImageUrl', ex)
        return null
      }
    },
    mainImageClass () {
      // We only want to hide this on non-mobile if we have a mobile subsitute
      if (this.image.mobile) {
        return 'prismic-image d-none d-md-block'
      // Fix to fit images in IE
      } else if (this.IEfix) {
        return 'prismic-image IEfix'
      }
      return 'prismic-image'
    },
    mobileImageClass () {
      return 'd-block d-md-none'
    },
    isSvg () {
      if (this.image.url && this.image.url.includes('.svg')) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.prismic-image[lazy='loaded'] {
  opacity: 0;
  animation-name: fadein;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease-out;
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
