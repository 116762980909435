<template>
    <section class="story-buttons">
        <b-container>
            <div class="inner">
                <div class="story-buttons__btn story-buttons__btn--category">
                    <b-link
                        v-if="category"
                        tabindex="0"
                        :to="'/our-values/' + category.toLowerCase()"
                        class="taglink"
                        :class="'taglink--' + category.toLowerCase()"
                        :style="`background-color: ${getCategoryColour(category)}`">
                      <span :style="`color: ${getCategoryTextColour(category)}`">
                        {{ category }}
                      </span>
                    </b-link>
                </div>
                <like-button class="story-buttons__btn story-buttons__btn--likeBtn" :content-title="bannerHeading[0].text" :content-uid="uid" />
                <div class="story-buttons__btn story-buttons__btn--submitStory">
                    <a href="#" class="submitButton"
                       @click.prevent="$bvModal.show('contact-modal')"
                    >
                        <img src="../../../assets/img/submit-story.svg" alt="submit-story">
                        <span>Submit story</span>
                    </a>
                </div>
            </div>
        </b-container>
    </section>
</template>

<script>
import LikeButton from "@/components/LikeButton.vue";
export default {
    name: "story-buttons",
    components: {
        LikeButton
    },
    props: ["uid", "category", "bannerHeading"],
};
</script>

<style lang="scss">
.story-buttons {
    margin-bottom: 55px;
    display: block;

    .inner {
        display: flex;
        align-items: center;
    }

    &__btn {
        margin-right: 21px;

        &--category {
            .taglink {
                margin: 0;
            }
        }

        &--likeBtn {
            button {
                padding-right: 25px;

                @include respond-below(sm) {
                    padding-right: 10px;
                }
            }
        }

        &--submitStory {
            .submitButton {
                transition: .2s ease-in-out;
                font-family: "HelveticaNeue";
                text-align: center;
                display: inline-block;
                font-size: 27px;
                line-height: 30px;
                padding: 6px 14px;
                padding-right: 25px;
                background: #3C3C3C;
                color: #ffffff;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: auto;
                    height: 26px;
                    margin-right: 12px;
                }

                &:hover {
                    background-color: #1D7880;
                }

                @include respond-below(sm) {
                    font-size: 18px;
                    line-height: 24px;
                    padding: 5px 10px;

                    img {
                        height: 16px;
                        margin-right: 5px;
                    }
                }
            }

            @include respond-below(sm) {
                margin-right: 0 !important;
            }
        }

        @include respond-below(sm) {
            margin-right: 10px;
        }
    }

    @include respond-below(sm) {
        margin-bottom: 40px;
    }
}
</style>
