<template>
  <div :class="cssClass">
    <div class="prismic-video-player__inner">
      <div
        v-if="video.video_id"
        class="prismic-video-player__video"
      >
        <vue-vimeo-player
          ref="player"
          :video-id="video.video_id.toString()"
          :controls="true"
          :options="vimeoOptions"
        />
        <div class="prismic-video-player__button-container" :class="isPlaying ? 'playing' : ''">
          <a
            @click.prevent="toggleVideoPlaying"
            title="Play the video"
            href="#"
            class="prismic-video-player__button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="118.316" height="137.291" viewBox="0 0 118.316 137.291">
              <path id="Polygon_3" data-name="Polygon 3" d="M68.646,0l68.646,118.316H0Z"
                transform="translate(118.316) rotate(90)" fill="#fff" opacity="0.701" />
            </svg>
          </a>
        </div>
      </div>
      <div v-else class="prismic-video-player__not-authorised-placeholder">
        <p>No video has been specified to display here.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
// Built with https://github.com/dobromir-hristov/vue-vimeo-player/tree/master
export default {
  name: 'prismic-video-player',
  components: {
    vueVimeoPlayer
  },
  data () {
    return {
      // https://github.com/vimeo/player.js/#embed-options
      vimeoOptions: {
        byline: false,
        title: false,
        transparent: true,
        autopause: true,
        responsive: true
      },
      isPlaying: false
    }
  },
  props: {
    /**
     * A prismic video object
     */
    video: {
      type: Object,
      default () {
        return null
      }
    },
    width: {
      type: Number,
      default () {
        return 640
      }
    },
    height: {
      type: Number,
      default () {
        return 320
      }
    }
  },
  // handle player events
  mounted() {
    const player = this.$refs.player
    player.$on('play', () => {
      this.isPlaying = true
      this.trackVideoEvent('play', this.video.title || '')
    })
    player.$on('pause', () => {
      this.isPlaying = false
      this.trackVideoEvent('pause', this.video.title || '')
    })
  },
  methods: {
    stop () {
      this.$refs.player.pause()
    },
    toggleVideoPlaying () {
      const player = this.$refs.player
      if (this.isPlaying) {
        player.pause()
      } else {
        player.play()
      }
      this.isPlaying = !this.isPlaying
    }
  },
  computed: {
    /**
     * Component CSS class
     */
    cssClass () {
      const cssClass = `prismic-video-player prismic-video-player--${
        this.isPlaying ? 'playing' : 'paused'
      }`
      return cssClass
    },
    /**
     * The video style attr object
     * @returns {{width: string, height: string}}
     */
    videoStyle () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  }
}
</script>

<style lang="scss">
.prismic-video-player {
  position: relative;

  &__not-authorised-placeholder {
    background-color: black;
    padding-top: 56%;
    text-align: center;
    position: relative;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }

  &__inner {
    //display: inline-block;
    position: relative;
    max-width: 100%;
    height: 100%;
  }

  &--playing {
    .prismic-video-player__button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 30px;

      svg{
        width: 30px;
      }

      @include respond-below(sm) {
        bottom: 10px;
        right: 10px;
      }
    }
  }

  &__container {
    position: relative;
  }

  &__video {
    --video--width: 1296;
    --video--height: 540;

    position: relative;
    overflow: hidden;
    max-width: 100%;
    background: black;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__button-container {
    position: absolute;
    top: calc(calc(100% - 174px)/2);
    left: calc(calc(100% - 150px) /2);
    width: 150px;
    height: 175px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.playing {
      z-index: -1;
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    width: 150px;
    height: auto;
    transition: none;

    svg {
      width: 150px;
      height: auto;
      transition: none;

      path {
        transition: fill 0.3s ease-in-out;
      }
    }

    &:focus,
    &:hover {
      svg {
        .circle {
          fill: #ddd;
        }
      }
    }

    @include respond-below(sm) {
      width: 30px;
      svg {
        width: 30px;
      }
    }
  }
}
</style>
