<template>
  <div class="category-page">
    <b-container v-if="prismicDoc.data.title">
      <h2 class="category-page__heading">{{ prismicDoc.data.title[0].text }}</h2>
    </b-container>
    <NavBar :categoriesList="categoriesList" />
    <Region v-for="(document, i) in prismicDocs" :key="'category-' + i" :document="document" />
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <StoryCTA :slice="slice" v-if="slice.slice_type === 'cta_baner'" :key="sliceIndex" />
      <StoriesFooterLinks :slice="slice" v-if="slice.slice_type === 'story_links'" :key="sliceIndex" />
    </template>
  </div>
</template>
<script>
import Region from "@/components/slices/regions-page/Region";
import NavBar from "@/components/slices/regions-page/NavBar";
import StoryCTA from "@/components/slices/shared/StoryCta";
import StoriesFooterLinks from "@/components/slices/shared/StoriesFooterLinks";

export default {
  name: "category-slices",
  components: {
    NavBar,
    Region,
    StoryCTA,
    StoriesFooterLinks
  },
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null;
      },
    },
    prismicDocs: {
      type: Array,
      default() {
        return null;
      },
    },
    categoriesList: {
      type: Array,
      default() {
        return null;
      },
    },
    selectedCategory: {
      type: String,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="scss">
.category-page {
  &__heading {
    margin-bottom: 100px;

    @include respond-below(md) {
      margin-bottom: 30px;
    }
  }
}
</style>
