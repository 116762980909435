<script>
import Poll from "@/components/Poll.vue";
import LikeButton from "@/components/LikeButton.vue";
import SatisfactionSurvey from "@/components/SatisfactionSurvey.vue";

export default {
  name: "Polls.vue",
  components: {SatisfactionSurvey, LikeButton, Poll},
  data() {
    return {
      categories: [
        'News',
        'Insights',
        'Community',
        'Operations',
        'Inclusion'
      ]
    };
  },
}
</script>

<template>
  <article>
    <br><br><br><br><br><br><br><br>

    <h1>Category colours</h1>
    <div>
      <p v-for="category in categories" :style="`background-color: ${getCategoryColour(category)}`">
        <span :style="`color: ${getCategoryTextColour(category)}`">
            {{ category }}
            </span>
      </p>
    </div>


    <h1>Example Like Button</h1>
    <like-button content-title="Page or block title here" content-uid="unique-page-or-block-uid"/>
    <br><br><br>
    <h1>Example Poll</h1>
    <poll poll-uid="asdasdasd"/>
    <poll poll-uid="asdasdasd1123"/>
    <br><br><br>
    <h1>Example Satisfaction Survey</h1>
    <satisfaction-survey uid="uinique-page-or-block-id" title="Page or block title here"/>
  </article>
</template>

<style scoped lang="scss">

</style>
