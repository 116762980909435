<template>
    <section class="story-video">
        <b-container>
            <b-row>
                <b-col>
                    <div class="video-box">
                        <div class="vimeo-video">
                            <vueVimeoPlayer :video-id="slice.primary.video_url.video_id.toString()" ref="storyPlayer"
                                :controls="true" @ready="onReady" @bufferend="isPlaying = true"
                                @playing="isPlaying = true" @pause="isPlaying = false"
                                class="vimeo-video__embed-container" :options="{ responsive: true }">
                            </vueVimeoPlayer>

                            <div v-if="playerReady" class="vimeo-video__playbtn" :class="isPlaying ? 'playing' : ''">
                                <button v-if="isPlaying" @click="pauseVideo()" class="pausebtn">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.181" height="33.271"
                                        viewBox="0 0 22.181 33.271">
                                        <g id="_211871_pause_icon" data-name="211871_pause_icon"
                                            transform="translate(-128 -64)" opacity="0.7">
                                            <path id="Path_493" data-name="Path 493"
                                                d="M136.318,96.214V65.048A1.048,1.048,0,0,0,135.261,64h-6.2A1.048,1.048,0,0,0,128,65.048V96.214a1.056,1.056,0,0,0,1.057,1.057h6.2A1.05,1.05,0,0,0,136.318,96.214Z"
                                                fill="#fff" />
                                            <path id="Path_494" data-name="Path 494"
                                                d="M295.261,64h-6.2A1.054,1.054,0,0,0,288,65.048V96.214a1.056,1.056,0,0,0,1.057,1.057h6.2a1.056,1.056,0,0,0,1.057-1.057V65.048A1.048,1.048,0,0,0,295.261,64Z"
                                                transform="translate(-146.137)" fill="#fff" />
                                        </g>
                                    </svg>
                                </button>
                                <button v-else @click="playVideo()" class="playbtn">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="118.316" height="137.291"
                                        viewBox="0 0 118.316 137.291">
                                        <path id="Polygon_3" data-name="Polygon 3" d="M68.646,0l68.646,118.316H0Z"
                                            transform="translate(118.316) rotate(90)" fill="#fff" opacity="0.701" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <prismic-rich-text class="caption" :field="slice.primary.caption" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
    name: "story-video",
    components: {
        vueVimeoPlayer
    },
    data() {
        return {
            playerReady: false,
            isPlaying: false
        };
    },
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        }
    },
    methods: {
        onReady() {
            this.playerReady = true;
        },
        playVideo() {
            this.$refs.storyPlayer.play();
        },
        pauseVideo() {
            this.$refs.storyPlayer.pause();
        }
    },
};
</script>

<style lang="scss">
.story-video {
    margin-bottom: 80px;

    .video-box {
        .vimeo-video {
            position: relative;
            margin: 0;

            &__embed-container {
                margin-bottom: 1rem;
            }

            &__playbtn {
                pointer-events: all;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                cursor: pointer;

                button {
                    border: none;
                    outline: none;
                    background: none;
                    transition: .2s ease-in-out;

                    svg {
                        width: 130px;
                        height: auto;

                        @include respond-below(md) {
                            width: 70px;
                        }
                    }

                    &.pausebtn {
                        svg {
                            width: 30px;
                            height: auto;
                        }
                    }

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                &.playing {
                    transform: none;
                    top: auto;
                    left: auto;
                    right: 16px;
                    bottom: 16px;
                }
            }
        }

        p {
            font-family: "ArialBold", Fallback, sans-serif;
        }
    }

    @include respond-below(sm) {
        margin-bottom: 40px;
    }
}
</style>
