<template>
    <section class="story-polls" :class="fullWidth ? 'story-polls--full' : ''">
        <b-container>
            <b-row>
                <b-col cols="12" :xl="fullWidth ? 12 : 6">
                    <prismic-rich-text class="mb-3" :field="slice.primary.poll_title" />
                    <prismic-rich-text class="mb-5" :field="slice.primary.poll_content" />
                    <div v-if="slice.items.length > 0" class="story-polls__polls">
                        <poll v-for="poll in slice.items" :key="poll.poll_uid" :poll-uid="poll.poll_uid" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import Poll from "@/components/Poll.vue";
export default {
    name: "story-polls",
    components: {
        Poll
    },
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        },
        uid: {
            type: String,
            default() {
                return null;
            },
        },
        fullWidth: {
            type: Boolean,
            default() {
                return false;
            },
        },
    }
};
</script>

<style lang="scss">
.story-polls {
    &--full{
        .container{
            padding: 0;

            .story-polls__polls{
                .polls:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>