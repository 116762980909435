<template>
  <div class="polls">
    <h3>{{ pollData.title }}</h3>
    <div class="polls__poll polls__poll--submitted">
      <ul>
        <li v-for="response in pollData.responses" :key="response.uid" @click="submitResponse(response.uid)" class="polls__poll polls__poll--submitted">
          <span :class="`round ${submittedResponseUid === response.uid ? 'active' :''}`" />
          <div class="label">
            <span class="text">{{ response.title }}</span>
            <span class="text" v-if="hasSubmitted()">{{ calculateWidth(response.count) }}%</span>
            <span class="count" :style="{ width: calculateWidth(response.count) + '%', backgroundColor: '#F9B000' }" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "poll-slice",
  props: ['poll-uid'],
  data() {
    return {
      pollData: {
        title: '',
        responses: [],
      },
      submitted: false,
      submittedResponseUid: null,
      totalResponses: 0,
    };
  },
  mounted() {
    if (this.hasSubmitted()) {
      this.submitted = true;
      this.submittedResponseUid = this.getSubmittedResponseUid();
    }
    this.loadPollData();
  },
  methods: {
    async loadPollData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/surveys/api/${this.pollUid}`);
        this.pollData = response.data;
        this.totalResponses = this.pollData.responses.reduce((total, current) => total + current.count, 0);
      } catch (error) {
        console.error('Error fetching poll data:', error);
      }
    },
    async submitResponse(responseUid) {
      this.submittedResponseUid = responseUid;
      if (!this.submitted) {
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/surveys/api/response`, {
          survey_uid: this.pollData.uid,
          response_uid: responseUid,
        });
        const responseIndex = this.pollData.responses.findIndex(response => response.uid === responseUid);
        const clickedResponse = this.pollData.responses.find(response => response.uid === responseUid);
        if (responseIndex !== -1) {
          this.pollData.responses[responseIndex].count += 1;
          this.totalResponses += 1;
        }
        if (clickedResponse) {
            clickedResponse.clicked = !clickedResponse.clicked;
        }
        this.setSubmittedCookie(responseUid);
        this.submitted = true;
      } catch (error) {
        console.error('Error submitting response:', error);
      }
    }
    },
    calculateWidth(count) {
      if(!this.submitted) return '0';
      if (this.totalResponses === 0) return '0';
      let countNum = Math.round((count / this.totalResponses) * 100);
      return Math.min(Math.max(countNum, 0), 100);
    },
    setSubmittedCookie(responseUid) {
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + (365 * 24 * 60 * 60 * 1000)); // Cookie expires in 1 year
      const expires = "expires=" + expiryDate.toUTCString();
      document.cookie = `submittedPoll_${this.pollUid}=${responseUid}; ${expires}; path=/`;
    },
    // get the responseUid from the cookie
    getSubmittedResponseUid() {
      const cookieName = `submittedPoll_${this.pollUid}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(cookieName) === 0) {
          return c.substring(cookieName.length, c.length);
        }
      }
      return '';
    },
    hasSubmitted() {
      const cookieName = `submittedPoll_${this.pollUid}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(cookieName) === 0) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>

<style lang="scss">
.polls {
  margin-bottom: 80px;

  h3 {
    line-height: 60px;
  }

  &__poll {
    transition: .2s ease-in-out;
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-block: 10px;
        display: flex;
        align-items: center;
        pointer-events: all;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .round {
          position: relative;
          display: block;
          width: 56px;
          height: 56px;
          background: #1D7880;
          border: 1px solid #707070;
          border-radius: 100%;
          margin-right: 35px;
          overflow: hidden;

          @include respond-below(sm) {
            margin-right: 16px;
            width: 45px;
            height: 45px;
          }
        }

        .label {
          flex: 1;
          font-size: 30px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "ArialBold";
          background: #7F1F78;
          align-self: center;
          min-height: 55px;
          padding: 0 20px;
          color: #ffffff;
          overflow: hidden;

          span.text {
            position: relative;
            z-index: 1;
            transition: .5s ease-in-out;
          }

          @include respond-below(sm) {
            font-size: 20px;
            min-height: 45px;
          }
        }
      }
    }

    &--submitted {
      ul {
        li {
          .round.active {
            &::after {
              content: '';
              width: 38px;
              height: 38px;
              position: absolute;
              top: 50%;
              left: 50%;
              border-radius: 100%;
              transform: translate(-50%, -50%);
              background: white;
              border: 1px solid #707070;
            }
          }

          .label {
            span.count {
              display: block;
              top: 0;
              right: 0;
              position: absolute;
              width: 0;
              height: 100%;
              background: #F9B000;
              text-align: right;
              transition: width .5s ease-in-out;
              z-index: 0;
            }
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    margin-bottom: 40px;

    h3 {
      line-height: 1.3;
      font-size: 24px;
    }
  }
}
</style>
