<template>
  <footer class="footer" id="site-footer">
    <b-container class="footer__main-footer">
      <b-row align-v="end">
        <b-col cols="12" lg="8" class="footer__main-footer__footermenu">
          <div v-if="globalContent.footer_logo" class="footer__main-footer__footermenu__logo">
            <b-link to="/" title="Visit the Network Rail website">
              <PrismicImage class="logo" :image="globalContent.footer_logo" />
            </b-link>
          </div>
          <div class="footer__main-footer__footermenu__menus">
            <ul v-if="getFooterMenus" class="menus menus--topmenu">
              <li v-for="(menu, index) in getFooterMenus" :key="menu.primary.label[0].text + '-' + index"
                class="menus__menu">
                <b-link class="menus__menu__link" v-if="menu.primary.link.type === 'homepage'" to="/">{{menu.primary.label[0].text}}</b-link>
                <b-link class="menus__menu__link" v-if="menu.primary.link.uid" :to="getPrismicLink(menu.primary.link)" :title="menu.primary.label[0].text">
                  {{ menu.primary.label[0].text }}
                </b-link>
                <b-link target="_blank" class="menus__menu__link" v-if="menu.primary.link.url"
                  :href="menu.primary.link.url">
                  {{ menu.primary.label[0].text }}
                </b-link>
              </li>
            </ul>
            <p class="copyText" v-if="globalContent.copyright_text">{{ globalContent.copyright_text }}</p>
            <ul v-if="globalContent && globalContent.sub_footer_menu" class="menus menus--bottommenu">
              <li v-for="(menu, index) in globalContent.sub_footer_menu" :key="menu.link_text + index"
                class="menus__menu">
                <router-link class="menus__menu__link" v-if="menu.menu_link.uid" :to="`/${menu.menu_link.uid}`">
                  {{ menu.link_text }}
                </router-link>
                <b-link class="menus__menu__link" v-if="menu.menu_link.url" :href="menu.menu_link.url">
                  {{ menu.link_text }}
                </b-link>
              </li>
            </ul>
          </div>
        </b-col>

        <b-col cols="12" lg="4" class="footer__main-footer__socials">
          <p v-if="getLabel('social_links_title')">{{ getLabel('social_links_title') }}</p>
          <div class="socials">
            <b-link :target="globalContent.open_newtab ? '_blank' : '_self'" v-if="getLabel('facebook_link')" :href="getLabel('facebook_link')">
              <img src="../assets/img/facebook-icon.svg" alt="facebook">
            </b-link>
            <b-link :target="globalContent.open_newtab ? '_blank' : '_self'" v-if="getLabel('instagram_link')" :href="getLabel('instagram_link')">
              <img src="../assets/img/instagram-icon.svg" alt="instagram">
            </b-link>
            <b-link :target="globalContent.open_newtab ? '_blank' : '_self'" v-if="getLabel('linkedin_link')" :href="getLabel('linkedin_link')">
              <img src="../assets/img/linkedin-icon.svg" alt="linkedin">
            </b-link>
            <b-link :target="globalContent.open_newtab ? '_blank' : '_self'" v-if="getLabel('twitter_link')" :href="getLabel('twitter_link')">
              <img src="../assets/img/twitter-icon.svg" alt="twitter">
            </b-link>
            <b-link :target="globalContent.open_newtab ? '_blank' : '_self'" v-if="getLabel('youtube_link')" :href="getLabel('youtube_link')">
              <img src="../assets/img/youtube-icon.svg" alt="youtube">
            </b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <a v-if="globalContent.show_back_to_top && showButton" @click.prevent="scrollToTop" href="#" id="topbtn"
      class="topbtn">
      <span>Back to top</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="29.875" height="18.556" viewBox="0 0 29.875 18.556">
        <path id="Path_406" data-name="Path 406" d="M15.313,0,0,13.824,15.313,27.648"
          transform="translate(28.761 2.238) rotate(90)" fill="none" stroke="#fff" stroke-width="3" />
      </svg>
    </a>
  </footer>
</template>

<script>
import PrismicImage from "@/components/PrismicImage"
export default {
  name: "MainFooter",
  components: {
    PrismicImage
  },
  data() {
    return {
      showButton: false
    };
  },
  computed: {
    globalContent() {
      if (this.$store.state.globalContent) {
        return this.$store.state.globalContent;
      } else {
        return "";
      }
    },
    getFooterMenus() {
      if (this.$store.state.menus && this.$store.state.menus.footerMenu.data.nav) {
        return this.$store.state.menus.footerMenu.data.nav;
      } else {
        return "";
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleScroll() {
      this.showButton = window.scrollY > window.innerHeight; // Show button when scrolled 300 pixels down
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss">
.footer {
  background: #EAEAEA;
  width: 100%;
  height: 100%;
  padding-block: 60px;
  position: relative;
  overflow: hidden;

  &__main-footer {
    position: relative;

    &__footermenu {
      display: flex;
      flex-wrap: wrap;

      &__logo {
        max-width: 170px;
        width: 100%;
        flex-basis: 170px;
        flex: 1;

        .logo {
          svg {
            max-width: 120px;
            width: 100%;
            height: auto;
          }

          @include respond-below(lg) {
            margin-bottom: 1rem;

            svg {
              max-width: 200px;
            }
          }
        }

        @include respond-below(sm) {
          max-width: 100%;
          flex-basis: 100%;
          text-align: right;
          margin-bottom: 2rem;
          display: block;

          .logo {
            svg{
              width: 120px;
            }
          }
        }
      }

      &__menus {
        flex: 1;
        width: 100%;

        .menus {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
          list-style: none;

          &__menu {
            position: relative;
            margin: 0;
            padding: 0;
            padding-right: 15px;
            margin-right: 15px;
            margin-bottom: 1rem;

            &__link {
              font-family: "Arial";
              font-weight: normal;
              font-size: 20px;
              line-height: 1;
              color: #000000;
              transition: .2s ease-in-out;
              text-decoration: underline !important;

              &:hover,
              &:focus {
                opacity: 0.5;
              }
            }

            &::after {
              content: "|";
              position: absolute;
              font-family: "Arial";
              font-weight: normal;
              font-size: 20px;
              color: #000000;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 2px;
              height: 100%;
            }

            &:last-child {
              padding-right: 0;

              &::after {
                display: none;
              }
            }
          }

          &--topmenu {
            margin-bottom: 15px;
          }

          &--bottommenu {
            .menus__menu {

              &__link {

                &:hover,
                &:focus {
                }
              }
            }
          }
        }

        p {
          font-size: 20px;
          line-height: 1;
          color: #000000;

          @include respond-below(sm) {
            margin-bottom: 2rem;
          }
        }

        @include respond-below(sm) {
          display: block;

          .menus {
            flex-basis: 100%;
            display: block;
            border-bottom: 1px solid #0000008e;
            margin-bottom: 2rem;

            &__menu {
              margin-right: 0;
              margin-bottom: 2rem;

              &::after {
                display: none;
              }
            }
          }
        }
      }

      @include respond-below(lg) {
        display: block;
        margin-bottom: 1rem;
      }
    }

    &__socials {
      p {
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 1;
        color: #000000;

        @include respond-below(sm) {
          margin-bottom: 1.5rem;
        }
      }

      .socials {
        display: flex;

        a {
          margin-bottom: 1rem;
          margin-right: 1rem;
          opacity: 0.5;
          transition: .2s ease-in-out;

          img {
            width: 40px;
            height: 40px;
          }

          &:hover {
            opacity: 1;
          }
        }

        @include respond-below(sm) {
          width: 100%;
          justify-content: space-between;
        }
      }

      @include respond-below(sm) {
        margin-bottom: 100px;
      }
    }

    @include respond-below(lg) {
      .row {
        align-items: flex-start !important;
      }
    }
  }

  .topbtn {
    font-family: "Arial";
    font-weight: normal;
    font-size: 20px;
    line-height: 1;
    transition: .2s ease-in-out;
    position: fixed;
    right: 20px;
    bottom: 20px;
    color: #ffffff;
    margin-bottom: 12px;
    padding: 1rem 1.5rem;
    background: #1D7880;
    border: none;
    border-radius: 50px;
    z-index: 999;

    svg {
      margin-left: 12px;
    }

    &:hover {
      color: #ffffff;
      background: #000000;
    }

    @include respond-below(sm) {
      position: absolute;
      padding: 1rem;
      margin-bottom: 0;
      right: auto;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
    }
  }

  @include respond-below(sm) {
    padding-block: 30px;
  }
}
</style>
