<template>
  <section v-if="slice" class="homepage-news">
    <b-container>
      <prismic-rich-text :field="slice.primary.news_title" class="homepage-news__heading" />
      <div v-if="slice.items">
        <VueSlickCarousel v-bind="options" @init="removeAriaHidden" @afterChange="removeAriaHidden">
          <div v-for="(item, index) in slice.items" :key="'homepage-news' + index" class="homepage-news__slider">
            <b-link :to="`${getPrismicLink(item.news_link)}#${item.news_item_link.id}`" class="story-link">
              <div class="image">
                <PrismicImage v-if="item.slider_image" :image="item.slider_image" class="img-fluid" />
                <b-skeleton-img v-else no-aspect height="324px"></b-skeleton-img>
              </div>
              <div class="content">
                <h3>{{ item.news_title }}</h3>
              </div>
            </b-link>
          </div>
        </VueSlickCarousel>
      </div>
    </b-container>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import PrismicImage from "@/components/PrismicImage";
export default {
  name: "HomepageNews",
  components: { VueSlickCarousel, PrismicImage },

  data() {
    return {
      options: {
        lazyLoad: "ondemand",
        dots: false,
        arrows: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              centerMode: true,
              centerPadding: '10px',
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          },
        ],
      },
    };
  },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  }
};
</script>

<style lang="scss">
.homepage-news {
  position: relative;
  background: #1D7880;
  background-size: cover;
  background-position: center center;
  padding: 80px 0;
  padding-bottom: 145px;
  width: 100%;
  height: 100%;
  overflow: hidden;


  &::after {
    content:  "";
    position: absolute;
    clip-path: polygon(0 0, 89% 0, 75% 100%, 0% 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #51ACB8;
    z-index: 0;
  }

  @include respond-below(sm) {
    padding: 45px 0;
  }

  .container{
    position: relative;
    z-index: 1;

    @include respond-below(sm) {
      padding: 0;
      padding-left: 20px;
      padding-bottom: 120px;
    }
  }

  &__heading {
    margin-bottom: 50px;
    padding-left: 42px;

    @include respond-below(ipad) {
      padding-left: 0;
    }

    @include respond-below(sm) {
      margin-bottom: 40px;
      padding-left: 10px;
    }
  }

  &__slider {
    margin: auto;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    height: 100%;
    display: flex !important;
    flex-direction: column;

    .image {
      width: 100%;
      padding-top: 67%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      flex: 1 1 auto;
      background: #fff;
      padding: 20px;
      width: 100%;
      text-align: left;
      height: 100%;

      h3 {
        margin-bottom: 1rem;
      }

      @include respond-below(ipad) {
        padding: 20px;

        h3 {
          margin-bottom: 10px;
        }
      }
    }

    &:hover {
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

    @include respond-below(sm) {
      padding-right: 12px;
    }
  }

  .slick-track {
    display: flex;
    margin:auto;

    .slick-slide {
      padding: 0 42px;
      height: inherit;

      >div {
        height: 100%;
      }

      @include respond-below(ipad) {
        padding: 0 35px;
      }

      @include respond-below(sm) {
        padding: 0;
      }
    }
  }

  .slick-arrow {
    width: 47px;
    height: 81px;
    background: url("../../../assets/img/slider-arrow-left.svg") no-repeat;
    background-position: center center;
    background-size: contain;
    z-index: 9;

    &::before {
      display: none;
    }

    &.slick-prev {
      left: -10px;

      @include respond-above(lg) {
        left: -20px;
      }
    }

    &.slick-next {
      right: -10px;

      @include respond-above(lg) {
        right: -20px;
      }

      background-image: url("../../../assets/img/slider-arrow-right.svg");
    }

    @include respond-below(ipad-pro) {
      height: 50px;
      width: 50px;

      &.slick-prev {
        left: -40px;
      }

      &.slick-next {
        right: -40px;
      }
    }

    @include respond-below(ipad) {
      height: 40px;
      width: 35px;
    }

    @include respond-below(sm) {
      height: 50px;
      width: 50px;
      bottom: -140px;
      top: auto;
      left: auto;
      right: 0;
      border: 1px solid #FFFFFF;
      border-radius: 100%;
      background-size: 1rem;

      &.slick-prev {
        right: 90px;
        left: auto;
      }
      &.slick-next {
        right: 20px;
        left: auto;
      }
    }
  }

  .slick-dots {
      bottom: -100px;
      display: inline-block;
      width: auto;
      margin-left: 10px;

      @include respond-below(sm) {
        bottom: 10px;
      }

      li {
        margin: auto;
        width: 20px;
        height: 15px;

        button {
          background: #ffffff;
          border-radius: 100%;
          width: 12px;
          height: 12px;
          margin: auto;
          opacity: 0.5;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
}
</style>
