<template>
  <article class="pageTheme pageTheme--withbg">
    <Slices v-if="prismicDoc && issueDataDump" :prismic-doc="prismicDoc" :issue-data="issueDataDump" />
    <!-- {{ prismicDoc }} -->
    <!-- Spinner while loading -->
    <Spinner v-if="!prismicDoc" />
  </article>
</template>

<script>
import Slices from "@/components/slices/past-editions/Slices";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "past-editions",
  components: {
    Slices,
    Spinner,
  },
  data() {
    return {
      prismicDoc: null,
      issueDataDump: null
    };
  },
  async mounted() {
    this.prismicDoc = await this.$prismic.client.getByUID('page', 'past-editions');
    this.issueDataDump = await this.$prismic.client.query(this.$prismic.Predicates.at('document.type','issue'))
    this.fetchSpotlightNavStatus(this.prismicDoc);
    const pageTitle = this.prismicDoc.data.title ? this.getRichTextAsText(this.prismicDoc.data.title) : ''
    this.trackPageView(pageTitle)
  }
};
</script>

<style scoped>
</style>
