<template>
  <section>
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <Content 
        :slice="slice" 
        v-if="slice.slice_type === 'content_section'" 
        :key="sliceIndex" 
        :sliceIndex="sliceIndex" 
      />
      <SiteMapList 
        :slice="slice"
        v-if="slice.slice_type === 'multilevel_list'"
        :key="sliceIndex"
        :sliceIndex="sliceIndex"
        />
    </template>
  </section>
</template>

<script>
import SiteMapList from "@/components/slices/sitemap/SiteMapList";
import Content from "@/components/slices/cookies/Content";

export default {
  name: 'sitemap-slices',
  components: {
    Content,
    SiteMapList
  },
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null;
      },
    },
  },
}
</script>

<style lang="scss">

</style>