<template>
  <section v-if="slice" class="homepage-hero">
    <div v-if="slice.items.length === 1" class="homepage-hero__image-container">
      <section>
        <div class="homepage-hero__slider__innerbox">
          <div class="homepage-hero__slider__innerbox__bg-image d-block d-md-none" :style="mobileBanner(slice.items[0])" />
          <div class="homepage-hero__slider__innerbox__bg-image d-none d-md-block" :style="banner(slice.items[0])" />

          <b-container>
            <b-row>
              <b-col md="12" lg="6" xl="5">
                <div class="top-box">
                  <prismic-rich-text :field="slice.primary.title1" />
                  <p v-if="slice.primary.subline">{{ slice.primary.subline }}</p>
                </div>
              </b-col>
              <b-col md="12" lg="6" xl="7">
                <div class="content-box">
                  <b-link :tabindex="index == 0 ? '0' : '-1'" :to="globalMakeLink(slice.items[0].link)">
                    <h1 class="content-box__title">
                      <span>
                        {{ slice.items[0].slider_title }}
                      </span>
                    </h1>
                    <p class="content-box__text">
                      <span>
                        {{ slice.items[0].slider_content }}
                      </span>
                    </p>
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </section>
    </div>
    <VueSlickCarousel :arrows="true" :dots="true" @init="removeAriaHidden" @afterChange="removeAriaHidden"
      :accessible="true">
      <section v-for="(item, index) in slice.items" :key="'homepage-hero' + index">
        <div class="homepage-hero__slider__innerbox">
          <div class="homepage-hero__slider__innerbox__bg-image d-block d-md-none" :style="mobileBanner(item)" />
          <div class="homepage-hero__slider__innerbox__bg-image d-none d-md-block" :style="banner(item)" />
          <b-container>
            <b-row>
              <b-col md="12" lg="6" xl="5">
                <div class="top-box">
                  <prismic-rich-text :field="slice.primary.title1" />
                  <p v-if="slice.primary.subline">{{ slice.primary.subline }}</p>
                </div>
              </b-col>
              <b-col md="12" lg="6" xl="7">
                <div class="content-box">
                  <b-link :tabindex="index == 0 ? '0' : '-1'" :to="globalMakeLink(item.link)">
                    <h1 class="content-box__title">
                      <span>
                        {{ item.slider_title }}
                      </span>
                    </h1>
                    <p class="content-box__text">
                      <span>
                        {{ item.slider_content }}
                      </span>
                    </p>
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </section>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "HomepageHero",
  components: { VueSlickCarousel },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      options: {
        type: "loop",
        arrows: false,
        dotsClass: "slick-dots",
        rewind: true,
        width: "100%",
        perPage: 1,
        infinite: false,
      },
    };
  },
  methods: {
    /**
     * get the banner bg image
     * @param item
     * @param imageSize
     * @returns {{backgroundImage: string}}
     */
    banner(item, imageSize) {
      let backgroundImageUrl = null;
      if (item.image) {
        if (!imageSize) {
          backgroundImageUrl = item.image.url;
        } else {
          if (item.image[imageSize]) {
            backgroundImageUrl = item.image[imageSize].url;
          } else {
            backgroundImageUrl = item.image.url;
          }
        }
      }
      if (backgroundImageUrl) {
        return { backgroundImage: `url('${backgroundImageUrl}')` };
      }
    },
    mobileBanner(item, imageSize) {
      let backgroundImageUrl = null;
      if (item.image) {
        if (!imageSize) {
          backgroundImageUrl = item.mobile_image.url;
        } else {
          if (item.image[imageSize]) {
            backgroundImageUrl = item.mobile_image[imageSize].url;
          } else {
            backgroundImageUrl = item.mobile_image.url;
          }
        }
      }
      if (backgroundImageUrl) {
        return { backgroundImage: `url('${backgroundImageUrl}')` };
      }
    },
  },
  mounted() {
    /** For acessibilty related links , need to disable tabindex from hero slick-arrow elements */
    this.globalDisableFocusForElements("slick-arrow");

    /**
     *  This is to fix an issue with slick slider, as it creates clones of the slideshow , which makes
     *  tabindex buggy as it creates tabindex copied from original clones, we disable the tabindexes on the clone
     */
    this.globalDisableFocusForElements("slick-cloned");

    let x = document.getElementsByClassName("homepage-hero__slider__innerbox");
    for (let i = 0; i < x.length; i++) {
      if (i == 4) {
        let contentBox = x[i].childNodes[x[i].childNodes.length - 1];
        contentBox.childNodes[0].setAttribute("tabindex", "-1");
      }
    }

  },
};
</script>

<style lang="scss">
.homepage-hero {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  &__slider {
    &__innerbox {
      width: 100%;
      //max-width: 1200px;
      margin: 0 auto;
      min-height: 710px;
      height: auto;
      padding-top: 300px;
      padding-bottom: 150px;
      position: relative;
      background: #1D7880;
      overflow: hidden;

      &__bg-image {
        z-index: 1;
        position: absolute;
        height: 710px;
        width: 100%;

        clip-path: polygon(65.5% 0, 99% 0, 96% 100%, 22% 100%);

        @include respond-below(lg) {
          clip-path: none;
        }


        margin: 0 auto;
        //right: 0;
        bottom: 0;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        // max-width breakpoints from bootstrap
        @include respond-above(xl) {
          max-width: 1504px;
          left: calc(50vw - 1504px / 2);
          //border: 1px solid red;
        }

        //clip-path: polygon(45% 0, 100% 0, 90% 100%, 0 100%);

        //&::after {
        //  content: '';
        //  position: absolute;
        //  bottom: 0;
        //  left: 0;
        //  width: 100%;
        //  height: 100%;
        //  z-index: 1;
        //  background: rgb(0, 0, 0);
        //  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
        //}

        @include respond-below(sm) {
          clip-path: none;
          width: 100%;
          height: calc(100% - 180px);
          background-position: center center;

          &::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 70%);
          }
        }
      }

      .top-box {
        position: relative;
        z-index: 2;
        overflow: hidden;

        h1,
        p {
          color: #fff;
          text-transform: uppercase;
          margin: 0;
        }

        h1 {
          font-family: "DINCondensed";
          font-size: 130px;
          letter-spacing: -0.9px;
          line-height: 1.1;
          margin-bottom: -1.5rem;

          @include respond-below(lg) {
            font-size: 100px;
          }

          @include respond-below(sm) {
            font-size: 70px;
            margin-bottom: -10px;
          }
        }

        p {
          letter-spacing: 0.8px;
          padding-right: 40%;

          @include respond-below(lg) {
            padding-right: 0;
          }

          @include respond-below(sm) {
            font-size: 14px;
            line-height: 1;
            padding-right: 40%;
          }
        }

        @include respond-below(sm) {
          margin-bottom: 20px;
        }
      }

      .content-box {
        position: relative;
        z-index: 2;
        text-align: left;
        color: #fff;
        overflow: hidden;
        max-width: 80%;
        margin-left: auto;

        span {
          transition: .2s ease-in-out;
          padding: 4px 10px 6px;
          box-decoration-break: clone;
          -webkit-box-decoration-break: clone;
        }

        &__title {
          color: #fff;
          margin-bottom: 15px;
          transition: .2s ease-in-out;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        }

        &__text {
          color: #fff;
          margin: 0;
          transition: .1s ease-in-out;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        }

        @include respond-below(md) {
          max-width: 100%;
        }

        @include respond-below(sm) {
          padding-right: 17%;
          position: absolute;
          bottom: 70px;

          h1 {
            font-size: 40px;
            line-height: 45px;
          }
        }
      }

      @include respond-below(sm) {
        padding-top: 50px;
        padding-bottom: 0;
        min-height: auto;
        padding-inline: 0;
        height: 664px;
        overflow: hidden;
      }

      &:hover,
      &:focus {
        .content-box {
          h1,
          p {
            span {
              text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
              background-color: black;
            }
          }
        }
      }
    }
  }

  .slick-slider {
    .slick-dots {
      bottom: 35px;

      @include respond-below(sm) {
        bottom: auto;
        top: 210px;
      }

      li {
        margin: auto;
        width: 25px;
        height: 14px;

        @include respond-below(sm) {
          margin: 0 1px 10px 1px;
          text-align: center;
        }

        button {
          border: 1px solid #fff;
          background: transparent;
          border-radius: 100%;
          width: 14px;
          height: 14px;
          margin: auto;

          &:before {
            display: none;
          }

          @include respond-below(sm) {
            width: 10px;
            height: 10px;
            margin: auto;
          }
        }

        &.slick-active {
          button {
            background: #fff;
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    margin-bottom: 50px;
  }
}
</style>
