<template>
  <div v-if="prismicDoc">
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <!-- Homepage slices-->
      <Hero v-if="slice.slice_type === 'hero'" :key="sliceIndex" :slice="slice" />
      <Welcome v-else-if="slice.slice_type === 'welcome'" :key="sliceIndex" :slice="slice" />
      <HomepageWelcomeWithVideo v-else-if="slice.slice_type === 'welcome-with-video'" :key="sliceIndex" :slice="slice" />
      <News v-else-if="slice.slice_type === 'news'" :key="sliceIndex" :slice="slice" />
      <StoryCards v-else-if="slice.slice_type === 'story_cards'" :key="sliceIndex" :slice="slice" :sliceIndex="sliceIndex"  />
      <RegionalStory v-else-if="slice.slice_type === 'regiona_story'" :key="sliceIndex" :slice="slice" />
      <FullWidthStory v-else-if="slice.slice_type === 'full_width_feature_story'" :key="sliceIndex" :slice="slice" />
      <!-- /Homepage slices-->

      <div v-else :key="sliceIndex">
        No component found for this slice with the slice_type {{ slice.slice_type }}
      </div>
    </template>
  </div>
</template>

<script>

import Hero from "@/components/slices/homepage/Hero";
import Welcome from "@/components/slices/homepage/Welcome";
import News from "@/components/slices/homepage/News";
import StoryCards from "@/components/slices/homepage/StoryCards";
import FullWidthStory from "@/components/slices/homepage/FullWidthStory";
import RegionalStory from "@/components/slices/homepage/RegionalStory";
import HomepageWelcomeWithVideo from "@/components/slices/homepage/WelcomeWithVideo";
export default {
  name: 'homepage-slices',
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null
      }
    }
  },
  components: {
    HomepageWelcomeWithVideo,
    FullWidthStory,
    StoryCards,
    News,
    Welcome,
    Hero,
    RegionalStory
  }
}
</script>

<style scoped>

</style>
