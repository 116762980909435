<template>
  <b-sidebar id="MainNavigationMenu" no-header width="330px" lazy backdrop v-bind="{ 'right': isMobile() }"
    bg-variant="#F9B000" backdrop-variant="null" @shown="navOpen = true" @hidden="navOpen = false"
    sidebar-class="sidebar">
    <template #default="{ hide }">
      <div class="icons">
        <a @click.prevent="hide" href="#" class="icons__close">
          <img class="animate__animated animate__faster" src="@/assets/img/close-icon.svg" alt="close" :class="{ 'animate__rotateIn': navOpen }">
        </a>
      </div>
      <ul class="menu">
        <li class="animate__animated" v-for="(menu, index) in $store.state.menus.mainMenu.data.nav" :key="menu.primary.label[0].text + '-' + index">
          <b-link @click="hide" v-if="menu.primary.link.type === 'homepage'" to="/">{{menu.primary.label[0].text}}</b-link>
          <b-link v-else-if="isEmailLink(menu.primary.link)" @click="hide" :href="menu.primary.link.url" :title="menu.primary.label[0].text">
            {{ menu.primary.label[0].text }}
          </b-link>
          <b-link v-else-if="menu.primary.link.uid" @click="hide" :to="getPrismicLink(menu.primary.link)" :title="menu.primary.label[0].text">
            {{ menu.primary.label[0].text }}
          </b-link>
          <b-link v-else @click="hide" :href="menu.primary.link.url" :title="menu.primary.label[0].text">
            {{ menu.primary.label[0].text }}
          </b-link>
        </li>
        <li class="animate__animated searchbox">
          <search-form/>
        </li>
      </ul>
    </template>
  </b-sidebar>
</template>

<script>
import SearchForm from "@/components/SearchForm.vue";
export default {
  name: "MainNavigation",
  components: {SearchForm},
  data() {
    return {
      navOpen: false
    }
  },
  methods: {
    isEmailLink(link) {
      if (link.link_type === 'Web' && link.url.includes('mailto:')) {
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss">
.sidebar {
  background-color: #F9B000 !important;
  position: fixed;
  left: 0;
  z-index: 1000;
  height: auto !important;
  overflow: auto;

  @include respond-below(sm) {
    width: 100% !important;
  }

  .icons {
    position: sticky;
    top: 0;
    text-align: right;
    background: #F9B000;
    padding: 35px 40px;
    width: 100%;
    min-height: 70px;
    border-bottom: 2px solid #ffffff;

    a {
      display: inline-block;
    }

    &__close {
      img {
        width: 35px;
        height: 35px;
      }
    }

    @include respond-below(sm) {
      padding: 20px 35px;
      min-height: 65px;

      &__close {
        margin: 0;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

  }

  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      padding-left: 42px;
      border-bottom: 2px solid #ffffff;

      a {
        display: block;
        font-size: 22px;
        font-weight: normal;
        line-height: 32px;
        color: #3C3C3C;
        font-family: "ArialBold", Fallback, sans-serif;
        padding: 0;
        margin: 0;
        padding: 22px 0;
        transition: .2s ease-in-out;

        &:hover, &:focus {
          color: #ffffff;
        }
      }

      &:last-child {
        border-bottom: none;
        padding: 22px 42px;

        form {
          position: relative;

          input {
            width: 100%;
            min-height: 52px;
            border: none;
            outline: none;
            background-color: #F7D074;
            border-radius: 20px;
            overflow: hidden;
            padding-inline: 60px;
            background-position: 16px center;
            background-size: 30px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41.645' height='46.25' viewBox='0 0 41.645 46.25'%3E%3Cpath id='Path_438' data-name='Path 438' d='M15.668,31.308A15.552,15.552,0,0,0,22.145,29.9l5.74,8.134a2.609,2.609,0,1,0,4.266-3.007L26.469,26.97a15.653,15.653,0,1,0-10.8,4.337m0-26.09A10.436,10.436,0,1,1,5.232,15.654,10.436,10.436,0,0,1,15.668,5.218' transform='matrix(0.966, -0.259, 0.259, 0.966, 0, 8.445)' fill='%233c3c3c'/%3E%3C/svg%3E%0A");
          }

          button {
            border: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: auto;
            background: none;
            animation: slideArrow 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;

            svg {
              width: 30px;
            }
          }
        }
      }
    }

    @include respond-below(sm) {
      li {
        padding-inline: 18px;

        a {
          padding: 18px 0;
          font-size: 18px;
          line-height: 18px;
          font-weight: 300;
        }

        &:last-child {
          padding: 18px;

          form {
            input {
              padding-inline: 40px;
              background-position: 10px center;
              min-height: 40px;
              background-size: 20px;
            }
            button {
              svg {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideArrow {
  from {right: 25px;}
  to {right: 20px;}
}

@include respond-below(sm) {
  @keyframes slideArrow {
    from {right: 13px;}
    to {right: 8px;}
  }
}
</style>
