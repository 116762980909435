<template>
  <section id="community-section" class="community-section">
    <div class="community-section__bg-image" :style="backgroundImageStyle"></div>
    <b-container class="community-section__container">
      <b-row align-v="center">
        <b-col cols="12" lg="5"
          class="community-section__container__mainbox community-section__container__mainbox--content">
          <b-link tabindex="0" v-if="slice.primary.category" class="taglink"
            :style="`background-color: ${getCategoryColour(slice.primary.category)}`"
            :class="'taglink--' + slice.primary.category.toLowerCase()"
            :to="'/our-values/' + slice.primary.category.toLowerCase()">
            <span :style="`color: ${getCategoryTextColour(slice.primary.category)}`">
              {{ slice.primary.category }}
            </span>
          </b-link>
          <b-link
            class="community-section__container__mainbox__contentbox"
            :to="`/${$store.state.currentIssueSlug}${$store.state.currentIssueSlug.indexOf('spring') > -1 ? '-regional-new' : '-regional-news'}`"
            :style="`background-color: ${getCategoryColour(slice.primary.category)}`">
            <h1 v-if="slice.primary.story_title"><span>{{ slice.primary.story_title }}</span></h1>
            <p v-if="slice.primary.story_contect"><span>{{ slice.primary.story_contect }}</span></p>
          </b-link>
        </b-col>
        <b-col cols="12" lg="7"
          class="community-section__container__mainbox community-section__container__mainbox--map">
          <div class="map">
            <img class="map__image" src="../../../assets/img/region-map.svg" alt="region-map">
            <div class="map__links">
              <b-link :class="`round ${globalSlugifyLink(region.css_class)}`" :href="`/${$store.state.currentIssueSlug}${$store.state.currentIssueSlug.indexOf('spring') > -1 ? '-regional-new' : '-regional-news'}#${globalSlugifyLink(region.region_title)}`" v-for="region in slice.items" :key="region.region_title" v-bind:style="{ top: region.top, left: region.left }">
                <img src="@/assets/img/region-icon.svg" alt="region-icon">
                <p class="bold">{{ region.region_title }}</p>
              </b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "regional-story",
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    }
  },
  computed: {
    /**
     * Get the bg image style
     * @returns {{}}
     */
    backgroundImageStyle() {
      const style = {}
      if (this.slice && this.slice.primary.background_image) {
        style.backgroundImage = `url(${this.slice.primary.background_image.url})`
      }
      return style
    }
  }
};
</script>

<style lang="scss">
.community-section {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-block: 30px;

  &__bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0) 70%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: rgb(106, 115, 119);
      background: linear-gradient(90deg, rgba(106, 115, 119, 0.5) 0%, rgba(211, 229, 238, 0.91) 70%);
    }

    @include respond-below(sm) {
      &:before {
        display: none;
      }
    }
  }

  &__container {
    &__mainbox {
      position: relative;
      z-index: 2;

      &--content {
        span {
          transition: .2s ease-in-out;
        }
        h1 {
          color: #fff;
          margin-bottom: 1rem;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
          transition: .2s ease-in-out;
        }
        p {
          color: #fff;
          padding-right: 20%;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
          transition: .2s ease-in-out;
          margin: 0;
        }

        @include respond-below(ipad) {
          max-width: 100%;
          h2 {
            font-size: 40px;
            line-height: 40px;
          }
          p {
            font-size: 25px;
            line-height: 30px;
          }
        }

        @include respond-below(sm) {
          h2 {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 15px;
          }
          p {
            font-size: 20px;
            line-height: 25px;
            padding: 0;
          }
        }

        @include respond-above(sm) {
          a {
            &:hover,
            &:focus {
              h1,
              p {
                span {
                  text-shadow: none;
                  background-color: black;
                }
              }
            }
          }
        }

        @include respond-below(sm) {
          a{
            h1,p {
              color: #000000;
              text-shadow: none;
            }
          }
        }
      }
      &--map {
        text-align: center;
        .map {
          position: relative;
          margin: auto;
          width: 607px;

          .round {
            width: 180px;
            height: 180px;
            position: absolute;
            top: 10%;
            left: 53%;
            border-radius: 50%;
            border: 4px solid #ffffff;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: rgb(81, 172, 184);
            -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.16);
            transition: 0.2s ease-in-out;
            padding: 10px 20px;
            cursor: pointer;

            &.large {
              width: 165px;
              height: 165px;
            }

            p {
              color: white;
              font-size: 22px;
              line-height: 1.1;
              text-align: center;
              margin: 0;
              margin-top: 8px;
              text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
            }

            &:nth-child(2) {
              top: 40%;
              left: 25%;
            }

            &:nth-child(3) {
              top: 45%;
              left: 65%;
            }

            &:nth-child(4) {
              top: 70%;
              left: 10%;
            }

            &:nth-child(5) {
              top: 78%;
              left: 83%;
            }

            &:hover {
              transform: scale(1.1);
            }

            @include respond-below(lg) {
              width: 150px;
              height: 150px;
            }
          }

          @include respond-below(sm) {
            width: 300px;
            margin-bottom: 1rem;
            margin-right: 60px;

            img {
              width: 100%;
              height: auto;
            }

            .round{
              width: 100px;
              height: 100px;
              padding: 10px;

              &.large {
                width: 110px;
                height: 110px;
              }

              img{
                max-width: 30px;
              }

              p{
                margin-top: 5px;
                font-size: 14px;
                line-height: 1.1;
              }
            }
          }
        }
      }

      @include respond-below(sm) {

        .taglink{
          margin: 12px;
          margin-bottom: 35px;
        }

        &__contentbox {
          padding: 35px 12px;
          display: block;

          h1 {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 25px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    background-position: top center;
    padding: 0;
    padding-top: 2rem;

    .container {
      padding: 0;

      .row {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
