<template>
  <section class="story-footer-links">
    <b-container>
      <b-row>
        <b-col>
          <div class="story-footer-links__container">
            <ul>
              <li>
                <b-link to="/" title="Go to the homepage">
                  <img src="../../../assets/img/home-icon.svg" alt="Icon of a house" />
                </b-link>
              </li>
              <li>
                <b-link :to="makeLink(slice.primary.previous_link)">
                  <img src="../../../assets/img/previous-icon.svg" alt="Icon of a back arrow" />
                  <span>Previous article</span>
                </b-link>
              </li>
              <li>
                <b-link :to="makeLink(slice.primary.next_story_link)">
                  <img src="../../../assets/img/next-icon.svg" alt="Icon of a next" />
                  <span>Next article</span>
                </b-link>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "footer-links",
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    /** Creates correct link for different page and different content types */
    makeLink: function (link) {
      //console.log(link)

      /** For manual links i.e to community or issues pages whic are tabs for issue pages*/
      if (link.link_type === "Web") {
        return link.url.replace("https://", "/");
      }

      /** For page type links which are not custom post types */
      if (link.type === "page") {
        return `/${link.uid}`;
      }

      /** For regular and featured news , we serve the same link  */
      if (link.type === "story") {
        return `/story/${link.uid}`;
      }

      /** Default for all other custom post types */
      return `/${link.type}/${link.uid}`;
    },
  },
}
</script>

<style lang="scss">
.story-footer-links {
  margin-top: 120px;
  position: relative;
  background: #1D7880;

  &__container {
    padding-block: 80px;

    @include respond-below(ipad-pro) {
      padding-block: 50px;
    }

    @include respond-below(ipad) {
      padding-block: 30px;
    }

    img {
      width: 82px;
      height: 74px;

      @include respond-below(md) {
        width: 42px;
        height: 34px;
      }

      @include respond-below(sm) {
        width: 32px;
        height: 24px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      text-align: center;

      li {
        margin-right: 80px;

        a {
          font-size: 35px;
          line-height: 35px;
          color: #fff;
          font-family: 'ArialBold';
          text-decoration: none !important;

          span {
            margin-left: 25px;
          }

          @include respond-below(md) {
            font-size: 22px;
            line-height: 28px;

            span {
              margin-left: 10px;
            }
          }

          @include respond-below(sm) {
            font-size: 16px;

            span {
              margin-left: 0;
            }
          }
        }

        &:first-child {
          min-width: 35%;
          text-align: left;

          @include respond-below(lg) {
            min-width: auto;
          }

          @include respond-below(sm) {
            flex-basis: 40px;
            max-width: 40px;
            margin: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        @include respond-below(md) {
          margin-right: 2rem;
        }

        @include respond-below(sm) {
          margin-right: 0;
          flex: 1;
        }
      }
    }
  }

  @include respond-below(md) {
    margin-top: 40px;
  }
}
</style>
