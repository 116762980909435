<template>
  <issue v-if="this.currentIssueDocId" :issue-doc-id="this.currentIssueDocId" />
  <Spinner v-else />
</template>

<script>
import Spinner from "@/components/slices/shared/Spinner";
import Issue from "@/views/Issue";
export default {
  name: "home-page",
  components: {Issue, Spinner},
  data() {
    return {
      currentIssueDocId: null
    };
  },
  methods: {
    async getContent() {
      try {
        const homepageDoc = await this.$prismic.client.getSingle('homepage')
        const currentIssue = homepageDoc.data.current_issue
        // Set the current issue document
        this.currentIssueDocId = currentIssue.id;
      }catch (ex) {
        console.error('Error loading homepage', ex)
      }
    }
  },
  created() {
    this.getContent();
  }
}
</script>
<style lang="scss">
.homepage {
  max-width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
