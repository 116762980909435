<template>
  <div class="like-btn">
    <button class="like-btn__status" :class="{'like-btn__status--liked': isLiked}" @click="toggleLike">
      <img src="../assets/img/like-btn.svg" alt="likebtn">
      <span v-if="likeData.count">{{ likeData.count }}&nbsp;</span>
      <span>{{ isLiked ? 'Liked' : 'Like' }}</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: [
      'contentTitle',
      'contentUid',
  ],
  data() {
    return {
      likeData: {
        count: 0,
      },
      isLiked: false,
    };
  },
  mounted() {
    this.checkLikedStatus();
    this.loadLikeData();
  },
  methods: {
    async loadLikeData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/likes/api/like/${this.contentUid}`);
        this.likeData = response.data;
      } catch (error) {
        console.error('Error fetching like data:', error);
      }
    },
    async toggleLike() {
      const action = this.isLiked ? 'unlike' : 'like';
      try {
        // get url without hostname
        const url = window.location.href.replace(window.location.origin, '');
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/likes/api/like`, {
          content_uid: this.contentUid,
          content_title: this.contentTitle,
          action: action,
          url: this.likeData.url ?? url,
        });
        this.isLiked = !this.isLiked;
        if(this.likeData.count) {
          this.likeData.count += this.isLiked ? 1 : -1;
        } else {
          this.likeData.count = 1;
        }
        this.updateLikeCookie();
      } catch (error) {
        console.error('Error toggling like:', error);
      }
    },
    updateLikeCookie() {
      if (this.isLiked) {
        const expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (365*24*60*60*1000)); // Cookie expires in 1 year
        document.cookie = `likedContent_${this.contentUid}=true; expires=${expiryDate.toUTCString()}; path=/`;
      } else {
        document.cookie = `likedContent_${this.contentUid}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      }
    },
    checkLikedStatus() {
      const likedContentCookie = `likedContent_${this.contentUid}`;
      this.isLiked = document.cookie.split(';').some((item) => item.trim().startsWith(`${likedContentCookie}=true`));
    }
  },
};
</script>

<style lang="scss">
.like-btn{
  &__status {
    transition: .2s ease-in-out;
    font-family: "HelveticaNeue";
    text-align: center;
    display: inline-block;
    font-size: 27px;
    line-height: 30px;
    padding: 6px 14px;
    background: #3C3C3C;
    color: #ffffff;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: 26px;
      margin-right: 12px;
    }

    &:hover {
      background-color: #1D7880;
    }

    &--liked{
      background-color: #1D7880;
    }

    @include respond-below(sm) {
      font-size: 18px;
      line-height: 24px;
      padding: 5px 10px;

      img {
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}
</style>
