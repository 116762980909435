<template>
  <section v-if="storyData" class="issue-news">
    <b-container>
      <b-row v-for="(storyitem, index) in storyData"
             :key="storyitem.id + index"
             align-v="start"
             :id="storyitem.data.region ? globalSlugifyLink(storyitem.data.region) : storyitem.id"
             class="issue-news__row"
      >
        <b-col cols="12" class="issue-news__tag">
          <b-link v-if="!storyitem.data.news_type && storyitem.data.category" tabindex="0"
                  :to="'/our-values/' + storyitem.data.category.toLowerCase()" class="taglink"
                  :class="'taglink--' + storyitem.data.category.toLowerCase()"
                  :style="`background-color: ${getCategoryColour(storyitem.data.category)}`">
            <span :style="`color: ${getCategoryTextColour(storyitem.data.category)}`">
            {{ storyitem.data.category }}
            </span>
          </b-link>
          <div v-else>
            <b-link v-if="storyitem.data.region" tabindex="0"
                    :to="'/region/' + storyitem.data.region.toLowerCase()">
            <h2 >
            {{ storyitem.data.region }}
            </h2>
            </b-link>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="issue-news__gallery">
          <figure v-if="storyitem.data.gallery.length <= 1" class="issue-news__gallery__img">
            <PrismicImage :image="storyitem.data.gallery[0].image"/>
          </figure>
          <VueSlickCarousel v-else v-bind="options" @init="removeAriaHidden" @afterChange="removeAriaHidden">
            <figure v-for="img in storyitem.data.gallery" :key="img.image.url" class="issue-news__gallery__img">
              <PrismicImage :image="getStoryImage(img)" :lazyLoad="false"
              />
            </figure>
          </VueSlickCarousel>
        </b-col>
        <b-col cols="12" lg="6" class="issue-news__content">
          <div class="issue-news__content__storytext" :id="storyitem.id">
            <like-button :content-title="storyitem.data.title[0].text" :content-uid="storyitem.id"/>
            <h2>{{ storyitem.data.title[0].text }}</h2>
            <prismic-rich-text class="content" :field="storyitem.data.content"/>
          </div>
          <StoryPolls v-if="storyitem.data.body.length > 0 && storyitem.data.body[0].slice_type === 'polls_block'"
                      :slice="storyitem.data.body[0]" :uid="storyitem.id" :fullWidth="true"/>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";
import VueSlickCarousel from "vue-slick-carousel";
import LikeButton from "@/components/LikeButton.vue";
import StoryPolls from "@/components/slices/story/StoryPolls";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "news-page",
  components: {VueSlickCarousel, PrismicImage, LikeButton, StoryPolls},
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
    sliceIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      storyData: null,
      options: {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }
  },
  async mounted() {
    // load the story documents
    let storyDocIds = this.slice.items.filter(function (item) {
      if (item.news_items.id) {
        return true; // skip
      }
      return false;
    }).map(function (item) {
      return item.news_items.id;
    });
    const story = await this.$prismic.client.getByIDs(storyDocIds)
    this.storyData = story.results
  },
  methods: {
    /**
     * Get the a imageurl using its ID
     * @returns {{}}
     */
    getStoryImage(story) {
      try {
        return story.image;
      } catch (ex) {
        console.error('Error getting issue image', ex)
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.issue-news {
  @include respond-above(md) {
    .row {
      margin-inline: -48px;
      margin-bottom: 120px;
    }

    .row > * {
      padding-inline: 48px;
    }
  }

  &__row {
    position: relative;

    @include respond-below(md) {
      margin-bottom: 40px;
    }
  }

  &__tag {

    .taglink,
    h2 {
      margin-bottom: 30px;
    }

    @include respond-below(md) {
      .taglink {
        position: absolute;
        top: 10px;
        left: 22px;
        z-index: 1;
      }
    }
  }

  &__gallery {
    border-right: 1px solid #4A4A4A;
    overflow: hidden;
    margin-bottom: 1rem;

    figure {
      margin-bottom: 0;
    }

    @include respond-below(md) {
      border: none;
    }
  }

  &__content {
    &__storytext {
      position: relative;

      .like-btn {
        position: absolute;
        top: 0;
        right: 0;
      }

      h2 {
        max-width: calc(100% - 150px);
        margin-bottom: 2rem;

        @include respond-below(sm) {
          font-size: 26px;
          line-height: 1.2;
          margin-bottom: 1rem;
        }
      }
    }

    .story-polls {
      margin-top: 40px;
    }
  }
}
</style>
