<template>
    <section class="text-block">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div v-if="slice.primary.content" class="text-block__short-story">
                        <prismic-rich-text class="text-block__short-story__text" :field="slice.primary.content" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    name: "short-story",
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        },
    }
};
</script>

<style lang="scss">
.text-block {

    &__short-story {
        margin-bottom: 80px;
        background: #1D7880;
        padding: 40px;

        &__text {
            padding: 0 20px;
            padding-right: 200px;

            p {
                font-size: 29px;
                margin: 0;
                line-height: 34px;
                color: #ffffff;
            }

            @include respond-below(md) {
                padding: 0;

                p{
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                }
            }
        }

        @include respond-below(md) {
            padding: 20px;
            margin-bottom: 40px;
        }
    }
}
</style>
