<template>
  <header :class=" $store.state.showSpotlightNav ? 'page-header' : ''">
      <a href="#content-main" tabindex="0" class="header-accessibility-nav header-accessibility-nav__link">Skip to content</a>
    <div class="container pad35">
      <div class="menutext_mobile" v-if="$route.fullPath === '/'">{{ $store.state.currentIssueTitle }}</div>
      <p class="hamburg-icon animate__animated" :class="{ 'fixedtop animate__fadeInDown': headerfixedtop }">
        <span tabindex="0" class="hamburg-icon__icon" v-b-toggle.MainNavigationMenu aria-label="Open main navigation">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="41.868"
            height="31.063" viewBox="0 0 41.868 31.063">
            <defs>
              <clipPath id="clip-path">
                <path id="Path_4" data-name="Path 4" d="M0,8.063H41.868V-23H0Z" transform="translate(0 23)"
                  fill="none" />
              </clipPath>
            </defs>
            <g id="Group_5" data-name="Group 5" transform="translate(0 23)">
              <g id="Group_4" data-name="Group 4" transform="translate(0 -23)" clip-path="url(#clip-path)">
                <g id="Group_1" data-name="Group 1" transform="translate(2.026 2.026)">
                  <path id="Path_1" data-name="Path 1" d="M0,0H37.816" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-width="3" />
                </g>
                <g id="Group_2" data-name="Group 2" transform="translate(2.026 15.991)">
                  <path id="Path_2" data-name="Path 2" d="M0,0H37.816" transform="translate(0 0)" fill="none"
                    stroke="#fff" stroke-linecap="round" stroke-width="3" />
                </g>
                <g id="Group_3" data-name="Group 3" transform="translate(2.026 29.037)">
                  <path id="Path_3" data-name="Path 3" d="M0,0H37.816" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-width="3" />
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span v-if="!headerfixedtop && $route.fullPath === '/'" class="hamburg-icon__text">
          {{ $store.state.currentIssueTitle }}
        </span>
        <span v-if="!headerfixedtop && $route.fullPath !== '/'" class="hamburg-icon__spotlight">
          SPOTLIGHT
        </span>
      </p>
      <main-navigation :class="{ 'fixed-top': headerfixedtop }" />
      <div class="nr-logo">
        <img src="../assets/img/nr_white.svg" alt="Network Rail Logo">
      </div>
    </div>
    <div v-if="headerfixedtop" class="top-menu-mobile animate__animated" :class="{ animate__fadeInDown: headerfixedtop }">
      <div class="top-menu-mobile__one-line">
        <b-link to="/">SPOTLIGHT</b-link>
        <p>{{ $store.state.currentIssueTitle }}</p>
      </div>
    </div>
    <div v-if="$store.state.showSpotlightNav" id="content-main"></div>
  </header>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import objectFitImages from "object-fit-images";
export default {
  name: "MainHeader",
  components: { MainNavigation },
  data() {
    return {
      isClicked: true,
      headerfixedtop: false,
      spotLightNav: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleMainnav);
    window.addEventListener("scroll", this.handleMainnav);
    window.addEventListener("keypress", () => {
      /** Hide Acessibility Popup on key press */
      document.getElementsByClassName(
        "header-accessibility-nav"
      )[0].style.display = "none";
    });
    /** For acessibilty related links , we only need outline style when tab is clicked */
    window.addEventListener("keydown", (e) => {
      if (e.which === 9) {
        document.body.classList.add("accessibility-using-tab");
        //console.log(document.activeElement);

        /** IE fix, as on page/route change, tabindex fix does not work in I.E to show opoup again */
        document.getElementsByClassName(
          "header-accessibility-nav"
        )[0].style.display = "block";
      }
    });
    /** For acessibilty related links , we remove tab styles when mouse clicked */
    window.addEventListener("mousedown", () => {
      document.body.classList.remove("accessibility-using-tab");
    });

    /** object-fit-images lib style to fit images IE specific */
    objectFitImages();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleMainnav);
  },
  computed: {
    issueTitle() {
      return this.$store.state.currentIssueTitle;
    }
  },
  methods: {
    handleMainnav() {
      const homepageHero = document.querySelector(".homepage-hero");

      let scrollYActivate = 0;
      if (homepageHero) {
        scrollYActivate = homepageHero.scrollHeight;
      }

      if (window.pageYOffset > scrollYActivate) {
        this.headerfixedtop = true;
      } else {
        this.headerfixedtop = false;
      }
    },
  },
  /** For acessibilty, we need to watch for route change and then reset the tabindex, simulating page load  */
  watch: {
    $route() {
      let resetFocusItem = document.getElementsByClassName(
        "accessibility-reset"
      )[0];
      resetFocusItem.focus();
    },
  },
};
</script>

<style lang="scss">
/**  Style to fit images for IE using object-fit-images lib  */
.IEfix {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

header {
  position: relative;

  .hide {
    transform: translateY(-120%);
  }

  .header-accessibility-nav {
    background: #1D7880;
    display: block;
    left: 50%;
    padding: 10px;
    position: absolute;
    transform: translateY(-120%);
    z-index: 2;
    transition: transform 0.3s;
    margin-top: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    border-left: 1px solid white;
    text-decoration: underline !important;

    &:first-child {
      border-left: 0;
    }

    &:focus {
      transform: translateY(0%);
    }
  }

  .hamburg-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 100px;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    outline: none;

    &__icon {
      cursor: pointer;
      outline: none;

      svg {
        max-width: 34px;
        transition: 0.2s ease-in-out;
      }
    }

    &__text {
      font-size: 25px;
      line-height: 30px;
      color: #fff;
      font-family: "ArialBold";
      text-transform: uppercase;
      transition: 0.2s ease-in-out;
      margin-left: 21px;

      @include respond-below(sm) {
        display: none;
      }
    }

    &__spotlight{
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    &.fixedtop {
      position: fixed;

      .hamburg-icon__icon {
        border-radius: 50%;
        border: 4px solid #fff;
        background: #F9AF02;
        padding: 15px;
        width: 70px;
        height: 70px;

        svg {
          path {
            stroke: #3C3C3C;
          }
        }

        @include respond-below(sm) {
          width: 44px;
          height: 44px;
          padding: 9px 6px;
        }

        svg {
          height: 33px;
          width: 100%;
          display: block;

          @include respond-below(sm) {
            height: 17px;
            width: 24px;
            display: block;
          }
        }
      }
    }

    @include respond-below(sm) {
      top: 24px;
      right: 35px;

      &__icon {
        svg {
          width: 24px;
        }
      }

      &.fixedtop {
        top: 100px;
      }
    }
  }

  .nr-logo{
    display: none;
  }

  .menutext_mobile {
    position: absolute;
    z-index: 999;
    top: 30px;
    display: none;
    font-size: 16px;
    line-height: 18px;
    color: #fff;

    @include respond-below(sm) {
      display: block;
    }
  }

  .b-sidebar-outer{
    .sidebar{
      top: 0;
    }

    &.fixed-top{
      .sidebar{
        top: 60px;
      }
    }

    @include respond-above(sm) {
      .sidebar {
        top: 60px !important;
      }
    }
  }

  .top-menu-mobile {
    display: none;
    background: #1D7880;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    padding: 5px 35px;

    &__one-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;

      a {
        font-family: "DINCondensed";
        font-size: 10vw;
        line-height: 1;
        color: #ffffff;
      }

      h3 {
        color: #ffffff;
        font-family: "ArialBold", Fallback, sans-serif;
      }

      p {
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 1;
        font-family: "ArialBold", Fallback, sans-serif;
        color: #ffffff;
      }
    }

    &__second-line {
      margin: 0;
      text-align: center;
      font-size: 14.5px;
      font-family: "ArialBold", Fallback, sans-serif;
      line-height: 17px;
      font-weight: 600;
      letter-spacing: -0.1px;
      color: #fff;
    }

    @include respond-below(sm) {
      display: block;
    }
  }

  &.page-header {
    background: #1D7880;
    min-height: 250px;

    .nr-logo {
      display: block;
      text-align: right;
      position: relative;
      z-index: 1;
      padding-top: 110px;

      @include respond-below(sm) {
        padding-top: 30px;

        img {
          max-width: 100px;
        }
      }
    }

    .hamburg-icon {
      top: 100px;

      &__spotlight {
        display: inline-block;
        font-family: "DINCondensed";
        font-size: 80px;
        letter-spacing: -0.9px;
        line-height: 1.2;
        color: #ffffff;
        margin-left: 50px;

        @include respond-below(md) {
          font-size: 50px;
          line-height: 1;
        }

        @include respond-below(sm) {
          font-size: 30px;
          line-height: 1;
          margin-left: 1rem;
        }
      }

      @include respond-below(sm) {
        top: 30px;
        right: auto;

        &.fixedtop {
          top: 70px;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 55%;
      height: 100%;
      background: url(../assets/img/header-slanted.png) no-repeat;
      background-size: 100% 100%;
      background-position: right center;
      background-repeat: no-repeat;
    }

    @include respond-below(sm) {
      min-height: 80px;
    }
  }
}
</style>
