<template>
  <section>
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <Content :slice="slice" v-if="slice.slice_type === 'content_section'" :key="sliceIndex" :sliceIndex="sliceIndex" />
      <ContactForm v-else-if="slice.slice_type === 'contact_form'" :key="sliceIndex" />
    </template>
  </section>
</template>

<script>
import Content from "@/components/slices/cookies/Content";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'cookies-slices',
  components: {
    ContactForm,
    Content
  },
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null;
      },
    },
  },
}
</script>

<style lang="scss"></style>
