<template>
  <section class="issues-items">
    <b-container>
      <h1>Past editions</h1>
      <div class="issues-items__flex" v-if="slice && issueData">
        <div class="issues-items__flex__flexitem" v-for="issue in issueData" :key="issue.uid">
          <span v-if="issue">
            <router-link :to="getIssueLink(issue)" class="issues-items__flex__flexitem__content"
                         v-if="issue.type === 'issue'">
              <PrismicImage v-if="issueData" :image="getIssueImage(issue)"
                            :alt="`Thumbnail image of ${getRichTextAsText(issue.data.title)}`"/>
              <h3>{{ getRichTextAsText(issue.data.title) }}</h3>
            </router-link>
            <a :href="getIssueLink(issue)" class="issues-items__flex__flexitem__content"
               v-if="issue.type === 'past_issue'" target="_blank">
              <PrismicImage v-if="issueData" :image="getIssueImage(issue)"
                            :alt="`Thumbnail image of ${getRichTextAsText(issue.data.title)}`"/>
              <h3>{{ getRichTextAsText(issue.data.title) }}</h3>
            </a>
          </span>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";

export default {
  name: "Issues-Items",
  components: {PrismicImage},
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      issueData: null
    }
  },
  async mounted() {
    // load the issue documents
    let issueDocIds = this.slice.items.filter(function (item) {
      if (item.issue.id) {
        return true; // skip
      }
      return false;
    }).map(function (item) {
      return item.issue.id;
    });
    const issues = await this.$prismic.client.getByIDs(issueDocIds)
    this.issueData = issues.results
  },
  methods: {
    /**
     * Get the title using the its ID
     * @returns {{}}
     */
    getIssueDataTitle(index) {
      if (this.issueData) {
        return this.issueData.results[index].data.title[0].text;
      }
      return false;
    },

    /**
     * Get the a imageurl using its ID
     * @returns {{}}
     */
    getIssueImage(issue) {
      try {
        if (issue.type === 'issue' && issue.data.body[0].items[0].image) {
          console.log('issue.data.body[0].items[0]', issue.data.body[0].items[0])
          return issue.data.body[0].items[0].mobile_image ?? issue.data.body[0].items[0].image;
        }
        if (issue.type === 'past_issue') {
          return issue.data.image;
        }
      } catch (ex) {
        console.error('Error getting issue image', ex)
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.issues-items {
  margin-bottom: 80px;

  h1 {
    margin-bottom: 100px;
    font-size: 48px;
    line-height: 55px;

    @include respond-below(xl) {
      font-size: 35px;
      line-height: 43px;
    }
  }

  &__flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 120px;

    &__flexitem {
      flex-basis: calc(50% - 60px);
      width: 100%;

      &__content {
        display: block;
        background: #7F1F78;

        img {
          width: 100%;
          min-height: 420px;
          max-height: 420px;
          height: 100%;
          object-fit: cover;

          @include respond-below(md) {
            min-height: 250px;
            max-height: 250px;
          }

          @include respond-below(sm) {
            min-height: 237px;
            max-height: 237px;
          }
        }

        h3 {
          color: #fff;
          padding: 30px;
          padding-bottom: 40px;
        }
      }

      @include respond-below(md) {
        flex-basis: calc(50% - 20px);
      }

      @include respond-below(sm) {
        flex-basis: 100%;
      }

    }

    @include respond-below(md) {
      gap: 40px;
    }
  }

  @include respond-below(md) {
    margin-bottom: 40px;

    h1 {
      margin-bottom: 40px;
    }
  }
}
</style>
