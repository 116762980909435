<template>
  <section>
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <PageBanner
        :slice="slice"
        v-if="slice.slice_type === 'page_banner'"
        :key="sliceIndex"
      />
      <IssuesItems
        :slice="slice"
        v-if="slice.slice_type === 'past_issues'"
        :key="sliceIndex"
        />
      <StoryCTA
        :slice="slice"
        v-if="slice.slice_type === 'cta_baner'"
        :key="sliceIndex"
        />
      <StoriesFooterLinks
        :slice="slice"
        v-if="slice.slice_type === 'story_links'"
        :key="sliceIndex"
        />
    </template>
  </section>
</template>

<script>
import IssuesItems from "@/components/slices/past-editions/IssuesItems";
import PageBanner from "@/components/slices/shared/PageBanner";
import StoryCTA from "@/components/slices/shared/StoryCta";
import StoriesFooterLinks from "@/components/slices/shared/StoriesFooterLinks";

export default {
  name: 'past-issue-slices',
  components: {
    IssuesItems,
    PageBanner,
    StoryCTA,
    StoriesFooterLinks
  },
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null;
      },
    },
    issueData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
}
</script>

<style lang="scss">

</style>
