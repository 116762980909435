<template>
  <section v-if="slice" class="homepage-linked-stories">
    <div class="homepage-linked-stories__slantedBg" :class="slice.primary.theme"></div>
    <b-container>
      <div v-if="!isMobile()" class="homepage-linked-stories__mainbox homepage-linked-stories__mainbox--desktop">
        <div v-for="(item, index) in slice.items" :key="'homepage-linked-stories' + index"
          class="homepage-linked-stories__mainbox__box">
          <b-link v-if="item.link.link_type !== 'Web'" :to="linkResolver(item.link)"  tabindex="-1" class="homepage-linked-stories__mainbox__box__image">
            <PrismicImage v-if="item.image" :image="item.image" :alt="item.heading" />
          </b-link>
          <b-link v-else :href="item.link.url" tabindex="-1" class="homepage-linked-stories__mainbox__box__image">
            <PrismicImage v-if="item.image" :image="item.image" :alt="item.heading" />
          </b-link>
          <b-link v-if="item.link.link_type !== 'Web'" :to="linkResolver(item.link)"  class="homepage-linked-stories__mainbox__box__content">
            <b-link
              v-if="item.category"
              tabindex="0"
              :to="`/our-values/${item.category.toLowerCase()}`"
              class="taglink"
              :style="`background-color: ${getCategoryColour(item.category)}`"
              :class="'taglink--' + item.category.toLowerCase()">
              <span :style="`color: ${getCategoryTextColour(item.category)}`">{{ item.category }}</span>
            </b-link>
            <div class="story-link">
              <h2><span>{{ item.heading }}</span></h2>
              <p><span>{{ item.subheading }}</span></p>
            </div>
          </b-link>
          <b-link v-else :href="item.link.url" class="homepage-linked-stories__mainbox__box__content">
            <b-link
              v-if="item.category"
              tabindex="0"
              :to="`/our-values/${item.category.toLowerCase()}`"
              class="taglink"
              :style="`background-color: ${getCategoryColour(item.category)}`"
              :class="'taglink--' + item.category.toLowerCase()">
              <span :style="`color: ${getCategoryTextColour(item.category)}`">{{ item.category }}</span>
            </b-link>
            <div class="story-link">
              <h2><span>{{ item.heading }}</span></h2>
              <p><span>{{ item.subheading }}</span></p>
            </div>
          </b-link>
        </div>
      </div>

      <div v-if="isMobile()" class="homepage-linked-stories__mainbox homepage-linked-stories__mainbox--slider">
        <VueSlickCarousel v-bind="options">
          <div v-for="(item, index) in slice.items" :key="'homepage-linked-stories' + index"
            class="homepage-linked-stories__mainbox__box">
            <b-link v-if="item.link.link_type !== 'Web'" :to="linkResolver(item.link)" tabindex="-1" class="homepage-linked-stories__mainbox__box__image">
              <PrismicImage v-if="item.image" :image="item.image" :alt="item.heading" />
            </b-link>
            <b-link v-else :href="item.link.url" tabindex="-1" class="homepage-linked-stories__mainbox__box__image">
              <PrismicImage v-if="item.image" :image="item.image" :alt="item.heading" />
            </b-link>
            <b-link v-if="item.link.link_type !== 'Web'" :to="linkResolver(item.link)" class="homepage-linked-stories__mainbox__box__content">
              <b-link v-if="item.category" tabindex="0" :to="`/our-values/${item.category.toLowerCase()}`"
                class="taglink" :style="`background-color: ${getCategoryColour(item.category)}`"
                :class="'taglink--' + item.category.toLowerCase()">
                <span :style="`color: ${getCategoryTextColour(item.category)}`">{{ item.category }}</span>
              </b-link>
              <div class="story-link">
                <h2><span>{{ item.heading }}</span></h2>
                <p><span>{{ item.subheading }}</span></p>
              </div>
            </b-link>
            <b-link v-else :href="item.link.url" class="homepage-linked-stories__mainbox__box__content">
              <b-link v-if="item.category" tabindex="0" :to="`/our-values/${item.category.toLowerCase()}`"
                class="taglink" :style="`background-color: ${getCategoryColour(item.category)}`"
                :class="'taglink--' + item.category.toLowerCase()">
                <span :style="`color: ${getCategoryTextColour(item.category)}`">{{ item.category }}</span>
              </b-link>
              <div class="story-link">
                <h2><span>{{ item.heading }}</span></h2>
                <p><span>{{ item.subheading }}</span></p>
              </div>
            </b-link>
          </div>
        </VueSlickCarousel>
      </div>
    </b-container>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import PrismicImage from "@/components/PrismicImage";
import linkResolver from "@/prismic/link-resolver";
export default {
  name: "story-cards",
  methods: {linkResolver},
  components: { PrismicImage, VueSlickCarousel },
  data() {
    return {
      options: {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: '12px',
        dotsClass: "slick-dots custom-dot-class",
      },
    };
  },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
    sliceIndex: {
      type: Number,
      default() {
        return null;
      },
    }
  }
};
</script>

<style lang="scss">
.homepage-linked-stories {
  position: relative;
  background-size: cover;
  background: #1D7880;
  background-position: right bottom;

  @include respond-below(ipad) {
    background-position: center bottom;
  }

  .container{
    @include respond-below(sm) {
      padding: 0;
      padding-left: 20px;
      padding-bottom: 45px;
    }
  }

  &__slantedBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1D7880;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);


    &.Theme1 {
      background-color: #F9B000;
      left: -46%;
    }

    &.Theme2 {
      background-color: #51ACB8;
      left: -80%;
    }
  }

  &__mainbox {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
    padding: 140px 0;

    &__box {
      position: relative;
      max-width: calc(50% - 50px);
      min-height: 100%;
      width: 100%;
      overflow: hidden;
      transition: all 0.2s ease-in-out;

      &__image {
        img {
          width: 100%;
          height: 550px;
          object-fit: cover;
        }
      }

      &__content {
        padding: 38px;
        width: 100%;
        color: #fff;
        text-align: left;
        position: absolute;
        bottom: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 70%);

        h2 {
          color: #fff;
          margin-bottom: 20px;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);

          span {
            transition: .2s ease-in-out;
          }
        }

        p {
          color: #fff;
          margin-bottom: 0;
          padding-right: 30%;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);

          span {
            transition: .2s ease-in-out;
          }
        }
      }

      @include respond-above(sm) {

        &:hover,
        &:focus {
          .story-link {

            h2,
            p {
              span {
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
                background-color: black;
              }
            }
          }
        }
      }

      @include respond-below(xl) {
        max-width: calc(50% - 30px);

        &__image {
          img {
            height: 300px;
            width: 100%;
          }
        }

        &__content {
          padding: 20px;

          .taglink {
            margin-bottom: 20px;
          }

          p {
            padding: 0;
          }
        }
      }

      @include respond-below(ipad) {
        max-width: 48%;

        &__image {
          img {
            height: 450px;
            width: 100%;
          }
        }

        &__content {
          padding: 20px;

          h2 {
            padding: 0;
          }
        }
      }

      @include respond-below(sm) {
        max-width: 100%;
        padding-right: 12px;
        height: 100%;

        &__image {
          img {
            height: 320px;
            width: 100%;
          }
        }

        &__content {
          padding: 0;
          position: relative;

          h2 {
            margin-bottom: 15px;
          }

          p {
            padding: 0;
          }

          .taglink {
            margin: 1rem;
            position: absolute;
            top: -70px;
          }

          .story-link {
            background: #ffffff;
            display: block;
            padding: 35px 2rem;
            height: 100%;

            h2 {
              color: #000000;
              font-size: 30px;
              line-height: 40px;
              margin-bottom: 15px;
              text-shadow: none;
              padding-right: 20%;
            }

            p {
              color: #000000;
              font-size: 16px;
              line-height: 22px;
              padding: 0;
              margin: 0;
              text-shadow: none;
            }
          }
        }
      }

      &:hover {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      }
    }

    @include respond-below(xl) {
      padding: 80px 0;
    }

    &--slider {
      display: block;
      padding-bottom: 120px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      padding: 0 42px;
      height: inherit;

      >div {
        height: 100%;
      }

      @include respond-below(ipad) {
        padding: 0 35px;
      }

      @include respond-below(sm) {
        padding: 0;
      }
    }
  }

  .slick-arrow {
    width: 47px;
    height: 81px;
    background: url("../../../assets/img/slider-arrow-left.svg") no-repeat;
    background-position: center center;
    background-size: contain;
    z-index: 9;

    &::before {
      display: none;
    }

    &.slick-prev {
      left: -10px;

      @include respond-above(lg) {
        left: -20px;
      }
    }

    &.slick-next {
      right: -10px;

      @include respond-above(lg) {
        right: -20px;
      }

      background-image: url("../../../assets/img/slider-arrow-right.svg");
    }

    @include respond-below(ipad-pro) {
      height: 50px;
      width: 50px;

      &.slick-prev {
        left: -40px;
      }

      &.slick-next {
        right: -40px;
      }
    }

    @include respond-below(ipad) {
      height: 40px;
      width: 35px;
    }

    @include respond-below(sm) {
      height: 50px;
      width: 50px;
      bottom: -140px;
      top: auto;
      left: auto;
      right: 0;
      border: 1px solid #FFFFFF;
      border-radius: 100%;
      background-size: 1rem;

      &.slick-prev {
        right: 90px;
        left: auto;
      }

      &.slick-next {
        right: 20px;
        left: auto;
      }
    }
  }

  .slick-dots {
    bottom: -100px;
    display: inline-block;
    width: auto;
    margin-left: 10px;

    li {
      margin: auto;
      width: 20px;
      height: 15px;

      button {
        background: #ffffff;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        margin: auto;
        opacity: 0.5;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }
  }
}
</style>
