<template>
  <section class="category-page__navbar">
    <b-container>
      <b-row>
        <b-col>
          <ul class="category-page__navbar__menu">
            <li v-for="(category, i) in categoriesList" :key="i" class="animate__animated">
              <b-link :class="`${category.toLowerCase()}-border-color`" :to="`/our-values/${category.toLowerCase()}`">{{ category }}</b-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "CategoryNavBar",
  props: {
    categoriesList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="scss">
.category-page__navbar {
  padding: 0px;
  text-align: left;
  margin-right: 50px;
  margin-bottom: 100px;
  // overflow: hidden;

  @include respond-below(lg) {
    margin-top: 10px;
    margin-right: 0px;

    .container {
      padding: 0;

      @include respond-below(sm) {
        .row {
          margin: 0;

          .col {
            padding: 0;
          }
        }
      }
    }
  }

  @include respond-below(md) {
    margin-bottom: 50px;
  }

  &__menu {
    margin: 0 0 0 -10px;
    width: 100%;

    @include respond-below(lg) {
      padding: 0;
      margin: 0;
    }

    @include respond-below(sm) {
      padding: 0;
      margin: 0 0 0 0;
      padding-left: 1rem;
    }

    li {
      list-style: none;
      display: inline-block;
      margin-right: 40px;

      @include respond-below(md) {
        margin-right: 10px;
      }

      @include respond-below(sm) {
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }

      a {
        font-family: 'Arial';
        font-size: 30px;
        color: #000;
        line-height: 40px;
        margin: 0px;
        font-weight: 100;
        border-bottom: 4px solid transparent;
        padding: 0 0 5px 0;
        transition: all 0.2s ease-in-out;
        text-decoration: none !important;

        &.router-link-active {
          border-color: #E35100;
        }

        &:hover {
          border-color: #E35100;
        }

        @include respond-below(md) {
          font-size: 16px;
          padding: 0 0 4px 0;
        }
      }
    }
  }
}
</style>
