<template>
  <section class="cookie-content">
    <b-container>
      <b-row>
        <b-col cols="12" lg="8">
          <prismic-rich-text class="cookie-content__content" :field="slice.primary.page_content"/>
        </b-col>
      </b-row>
    </b-container>

  </section>
</template>

<script>
export default {
  name: 'cookie-content',
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    }
  }
}
</script>

<style lang="scss">
.cookie-content {
  &__content {
    margin-bottom: 50px;
    padding: 0 15px;

    h2 {
      color: #000;
      margin-bottom: 30px;

      @include respond-below(sm) {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 24px;
      }
    }

    p {
      color: #000;
      margin-bottom: 20px;
      min-height: 10px;

      a {
        color: #000;
        line-height: 35px;
        text-decoration: underline !important;

        @include respond-below(sm) {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 25px;
        }
      }

      strong {
        font-weight: bold;
      }

      @include respond-below(sm) {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 25px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 40px;

      @include respond-below(sm) {
        margin-bottom: 36px;
      }

      li {
        color: #000;
        line-height: 24px;
        margin-bottom: 20px;
        font-weight: 100;

        a {
          text-decoration: underline !important;
        }

        strong {
          font-weight: bold;
        }


        &::before {
          content: '';
          position: relative;
          width: 7px;
          height: 7px;
          background: #4A4A4A;
          display: inline-block;
          border-radius: 50%;
          top: -2px;
          margin-right: 10px;
        }

        @include respond-below(sm) {
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 5px;

          &::before {
            width: 5px;
            height: 5px;
            top: -3px;
            margin-right: 5px;
          }
        }
      }
    }

    @include respond-below(sm) {
      margin-bottom: 60px;
      padding: 0;
    }

  }
}
</style>
