export default {
  async getHeaderNavigation ($prismic) {
    const api = await $prismic.api
    let navigation = {}
    const results = await api.query(
      $prismic.predicates.at('document.tags', ['Main Navigation'])
    )
    navigation = results.results[0]
    return navigation
  },

  /**
   * Get all global menu items in a single call
   * @param $prismic
   * @returns {Promise<{footerMenu: T, mainMenu: T, footerLegalMenu: T}>}
   */
  async getAllMenus ($prismic) {
    const api = await $prismic.client
    let data = {}
    const menuTags = ['Footer Menu', 'Main Menu']
    const results = await api.query(
      // $prismic.predicates.at("document.type", "navigation")
      $prismic.predicates.any('document.tags', menuTags),
      { orderings: '[document.tags]' }
    )
    data = results.results
    const mainMenu = data.filter((i) => {
      return i.tags[0] === 'Main Menu'
    })[0]
    return {
      footerLegalMenu: data.filter((i) => {
        return i.tags[0] === 'Footer Legal Menu'
      })[0],
      footerMenu: data.filter((i) => {
        return i.tags[0] === 'Footer Menu'
      })[0],
      mainMenu
    }
  },
  async getGlobalContent ($prismic) {
    const globalContent = await $prismic.client.getSingle('global_content')
    return globalContent
  },
  /**
   * Get prismic documents by type
   * @param $prismic
   * @param documentType
   * @returns {Promise<*|{}>}
   */
  async getPrismicDocsByType ($prismic, documentType) {
    const api = await $prismic.api
    let items = {}
    const results = await api.query(
      $prismic.predicates.at('document.type', documentType),
      {
        pageSize: 2000
      }
    )
    //
    items = results.results
    return items
  },
  /**
   * Get prismic documents by type
   * @param $prismic
   * @param documentType
   * @returns {Promise<*|{}>}
   */
  async getPrismicDocsByTypes ($prismic, documentTypeArray) {
    const api = await $prismic.api
    let items = {}
    const results = await api.query(
      $prismic.predicates.any('document.type', documentTypeArray),
      {
        pageSize: 2000
      }
    )
    //
    items = results.results
    return items
  }
}
