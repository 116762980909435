import Vue from 'vue'
import Vuex from 'vuex'
import PrismicService from '../services/prismic-service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: null,
    globalContent: null,
    currentIssueTitle: "",
    currentIssueSlug: "",
    showSpotlightNav: null,
  },
  mutations: {
    SET_MENUS (state, data) {
      state.menus = data
    },
    SET_MENUS_ERROR (state, error) {
      state.menus = error
    },
    SET_GLOBAL_CONTENT(state, data) {
      state.globalContent = data
    },
    SET_CURRENT_ISSUE_TITLE(state, data) {
      state.currentIssueTitle = data
    },
    SET_CURRENT_ISSUE_SLUG(state, data) {
      state.currentIssueSlug = data
    },
    SHOW_SPOTLIGHT_NAV(state, data) {
      state.showSpotlightNav = data
    },
  },
  actions: {
    async fetchAllMenus ({ commit }, $prismic) {
      try {
        const menus = await PrismicService.getAllMenus($prismic)
        commit('SET_MENUS', menus)
      } catch (e) {
        console.error('Error getting menus', e)
        const error = 'Please create a main navigation document tagged with "Main Menu"'
        commit('SET_MENUS_ERROR', error)
      }
    },

    async fetchGlobalContent({commit}, {prismic}) {
      try {
        const globalContent = await PrismicService.getGlobalContent(prismic)
        if (globalContent.data) {
          commit('SET_GLOBAL_CONTENT', globalContent.data)
        }
      } catch (e) {
        console.error('Error getting menus', e)
        const error = 'Not Found'
        commit('SET_GLOBAL_CONTENT', error)
      }
    },
  },
  modules: {
  }
})
