<template>
    <section class="story-twocolumns">
        <b-container>
            <b-row>
                <b-col cols="12" lg="6" v-if="slice.primary.image">
                    <PrismicImage :image="slice.primary.image" :lazyLoad="false" class="story-twocolumns__image" />
                    <p v-if="slice.primary.caption" class="caption">
                      {{ slice.primary.caption }}
                    </p>
                </b-col>
                <b-col cols="12" lg="6">
                    <div class="story-twocolumns__content">
                        <h3 v-if="slice.primary.quote">{{ slice.primary.quote }}</h3>
                        <prismic-rich-text v-if="slice.primary.content" :field="slice.primary.content" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";
export default {
    name: "story-twocolumns",
    components: { PrismicImage },
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            }
        }
    }
};
</script>

<style lang="scss">
.story-twocolumns {
    margin-bottom: 80px;

  p {
    &.caption {
      font-size: 22px;
      line-height: 28px;
      font-family: "ArialBold";
      margin: 10px 0 0 0;

      @include respond-below(sm) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

    @include respond-above(md) {
        .row {
            margin-inline: -50px;
        }

        .row>* {
            padding-inline: 50px;

            &:first-child {
                border-right: 1px solid #4A4A4A;
            }
        }
    }

    &__image {
        img {
            width: 100%;
        }

        @include respond-below(md) {
            margin-bottom: 30px;
        }

    }

    &__content {
        h3 {
            font-size: 40px;
            line-height: 48px;
        }

        p {
            font-size: 28px;
            line-height: 38px;

            b,
            strong {
                font-family: 'ArialBold';
            }


        }

        @include respond-below(md) {
            h3 {
                font-size: 28px;
                line-height: 38px;
                margin-bottom: 1rem;
            }

            p{
                font-size: 22px;
                line-height: 28px;
            }
        }

        @include respond-below(sm) {
            h3 {
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 1rem;
            }

            p{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    @include respond-below(sm) {
        margin-bottom: 40px;
    }
}
</style>
