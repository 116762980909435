<script>
export default {
  name: "SearchForm",
  data() {
    return {
      searchTerm: this.$route.query.q
    };
  },
  methods: {
    submitSearch() {
      this.$router.push({ path: '/search', query: { q: this.searchTerm } });
    }
  }
}
</script>

<template>
  <form class="search-form" @submit.prevent="submitSearch">
    <input type="search" v-model="searchTerm" id="search" placeholder="Search">
    <button type="submit" id="al" aria-label="Search">
      <svg xmlns="http://www.w3.org/2000/svg" width="33.93" height="28.836" viewBox="0 0 33.93 28.836">
        <g id="Group_659" data-name="Group 659" transform="translate(-341.5 -695.361)">
          <path id="Path_488" data-name="Path 488" d="M13.357,0,0,13.357,13.357,26.714"
                transform="translate(373.309 723.136) rotate(180)" fill="none" stroke="#3c3c3c"
                stroke-width="3"/>
          <line id="Line_75" data-name="Line 75" x1="31.809" transform="translate(341.5 709.779)"
                fill="none"
                stroke="#3c3c3c" stroke-width="3"/>
        </g>
      </svg>
    </button>
  </form>
</template>

<style lang="scss">
.search-form {
  position: relative;

  input {
    height: 52px;
    width: 100%;
    outline: none;
    background-color: white;
    border-radius: 26px;
    border: 1px solid black;
    overflow: hidden;
    padding-inline: 60px;
    background-position: 16px center;
    background-size: 30px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41.645' height='46.25' viewBox='0 0 41.645 46.25'%3E%3Cpath id='Path_438' data-name='Path 438' d='M15.668,31.308A15.552,15.552,0,0,0,22.145,29.9l5.74,8.134a2.609,2.609,0,1,0,4.266-3.007L26.469,26.97a15.653,15.653,0,1,0-10.8,4.337m0-26.09A10.436,10.436,0,1,1,5.232,15.654,10.436,10.436,0,0,1,15.668,5.218' transform='matrix(0.966, -0.259, 0.259, 0.966, 0, 8.445)' fill='%233c3c3c'/%3E%3C/svg%3E%0A");
  }

  button {
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    background: none;
    right: 20px;

    svg {
      width: 30px;
    }
  }
}
</style>
