<template>
  <section>
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <PageBanner :slice="slice" v-if="slice.slice_type === 'page_banner'" :key="sliceIndex" :sliceIndex="sliceIndex" />
      <ShortStory :slice="slice" v-if="slice.slice_type === 'short_story'" :key="sliceIndex" :sliceIndex="sliceIndex" />
      <TeamSection :slice="slice" v-if="slice.slice_type === 'editorial_team'" :key="sliceIndex" :sliceIndex="sliceIndex" />
      <StoryCTA :slice="slice" v-if="slice.slice_type === 'cta_baner'" :key="sliceIndex" :sliceIndex="sliceIndex" />
      <StoriesFooterLinks :slice="slice" v-if="slice.slice_type === 'story_links'" :key="sliceIndex" :sliceIndex="sliceIndex" />
    </template>
  </section>
</template>

<script>
import TeamSection from "@/components/slices/editorial-team/TeamSection";
import PageBanner from "@/components/slices/shared/PageBanner";
import ShortStory from "@/components/slices/shared/ShortStory";
import StoryCTA from "@/components/slices/shared/StoryCta";
import StoriesFooterLinks from "@/components/slices/shared/StoriesFooterLinks";

export default {
  name: 'editorial-team-slices',
  components: {
    TeamSection,
    PageBanner,
    ShortStory,
    StoryCTA,
    StoriesFooterLinks
  },
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null;
      },
    },
  },
}
</script>

<style lang="scss"></style>