<template>
    <section class="story-welcometext">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div  v-if="slice.primary.short_story || slice.primary.short_story" class="story-welcometext__short-story">
                        <div v-if="slice.primary.short_story_title" class="story-welcometext__short-story__clock">
                            <img src="../../../assets/img/clock-icon.svg" alt="clock-icon">
                            <p>{{ slice.primary.short_story_title }}</p>
                        </div>
                        <div v-if="slice.primary.short_story" class="story-welcometext__short-story__text">
                            <p>{{ slice.primary.short_story }}</p>
                        </div>
                    </div>

                    <div v-if="slice.primary.story_description || slice.primary.quote" class="story-welcometext__storyContent">
                        <div v-if="slice.primary.story_description" class="story-welcometext__storyContent__storyText">
                            <prismic-rich-text :field="slice.primary.story_description" />
                        </div>
                        <div v-if="slice.primary.quote" class="story-welcometext__storyContent__storyQuote">
                            <strong>{{ slice.primary.quote }}</strong>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    name: "story-text-block",
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        },
    }
};
</script>

<style lang="scss">
.story-welcometext {

    &__short-story {
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        background: #1D7880;
        padding: 40px;

        &__clock{
            min-width: 200px;
            flex-basis: 200px;
            padding-right: 20px;
            border-right: 1px solid #FFFFFF;

            p{
                font-family: "HelveticaNeue";
                font-weight: bold;
                font-size: 29px;
                line-height: 34px;
                margin-top: 10px;
                margin-bottom: 0;
                color: #ffffff;

                @include respond-below(sm) {
                    font-size: 22px;
                    line-height: 30px;
                }
            }

            @include respond-below(md) {
                padding: 0;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border: none;
                border-bottom: 1px solid #FFFFFF;
            }
        }

        &__text{
            padding: 0 20px;
            padding-left: 50px;

            p{
                font-size: 29px;
                margin: 0;
                line-height: 34px;
                color: #ffffff;
            }

            @include respond-below(md) {
                padding: 0;
            }

            @include respond-below(sm) {
                p{
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }

        @include respond-below(md) {
            display: block
        }

        @include respond-below(sm) {
            padding: 20px;
            margin-bottom: 40px;
        }
    }

    &__storyContent{
        margin-bottom: 80px;
        display: flex;
        align-items: center;

        &__storyText{
            padding-right: 38px;
            border-right: 1px solid #4A4A4A;

          h3 {
            font-size: 40px;
            line-height: 48px;

            @include respond-below(sm) {
              font-size: 22px;
              line-height: 28px;
              margin-bottom: 1rem;
            }
          }
            p{
                font-size: 28px;
                line-height: 35px;
                color: #000000;
                margin-bottom: 30px;

                b,
                strong {
                    font-family: 'ArialBold';
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }

            @include respond-below(ipad-pro) {
                border: none;
                padding: 0;
            }

            @include respond-below(sm) {
                p{
                    margin-bottom: 20px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        &__storyQuote {
            min-width: 500px;
            flex-basis: 500px;
            padding-left: 38px;

            strong {
                font-size: 40px;
                line-height: 46px;
                margin: 0;
              font-family: 'ArialBold';
            }

            @include respond-below(ipad-pro) {
                flex-basis: auto;
                margin-bottom: 40px;
                padding: 0;
            }

            @include respond-below(sm) {
                margin-bottom: 20px;
                strong{
                    font-size: 22px;
                    line-height: 28px;
                }
            }
        }

        @include respond-below(ipad-pro) {
            flex-wrap: wrap;
            align-items: flex-start;
            flex-direction: column-reverse;
        }

        @include respond-below(sm) {
            margin-bottom: 40px;
        }
    }
}
</style>
