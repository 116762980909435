<template>
  <b-modal class="story-cta__modal" id="contact-modal" title="Contact Form"
           size="lg"
           :cancel-disabled="true" hide-footer hide-backdrop
           centered
  >
    <template v-slot:modal-header>
      <b-button class="close-button" @click="$bvModal.hide('contact-modal')">
        {{ getGlobalContentField('contact_form_close_button_label') }}
      </b-button>
    </template>
    <b-container fluid="md" class="contact-form">
      <b-form @submit.prevent="sendContactForm" class="row">
        <p class="contact-form__intro col-10">
          {{ getGlobalContentField('contact_form_introduction') }}
        </p>
        <b-form-group
            id="input-group-1"
            label="First Name"
            label-for="input-name"
            class="col-6"
        >
          <b-form-input
              id="input-name"
              v-model="contact.first_name"
              type="text"
              required
              placeholder="Your first name"></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            label="Last Name"
            label-for="input-last-name"
            class="col-6"
        >
          <b-form-input
              id="input-name"
              v-model="contact.last_name"
              type="text"
              required
              placeholder="Your last name"></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-2"
            label="Email"
            label-for="input-email">
          <b-form-input
              id="input-email"
              v-model="contact.email"
              type="email"
              required
              placeholder="Your email address"></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-3"
            label="Message:"
            label-for="textarea-message">
          <b-form-textarea
              id="textarea-message"
              v-model="contact.message"
              required
              placeholder="Type your message here"
              rows="3"></b-form-textarea>
        </b-form-group>

        <b-button type="submit" class="btn">Submit</b-button>
        <p class="contact-form__thanks" v-if="has_submitted_message">
          {{ getGlobalContentField('contact_form_thank_you_message') }}
        </p>
      </b-form>
    </b-container>
  </b-modal>

</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';


export default {
  name: 'ContactForm',
  data() {
    return {
      has_submitted_message: false,
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    async sendContactForm() {
      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/usermessages/send-email/`, {
        name: this.contact.first_name + ' ' + this.contact.last_name,
        email: this.contact.email,
        message: this.contact.message
      }, {
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken')
        }
      })
          .then(response => {
            this.has_submitted_message = true;
            this.contact = {
              first_name: '',
              last_name: '',
              email: '',
              message: ''
            };
          })
          .catch(error => {
            console.error('Error sending message:', error);
            alert('Failed to send message.');
          });
    }
  }
};
</script>

<style lang="scss">
.contact-form {
  .form-group {
    label {
      display: none !important;
    }

    input, textarea {
      margin-bottom: 19px;
      padding: 17px;
      border-radius: 5px;
      border: 0;
    }

    textarea {
      height: 177px;
    }
  }

  &__intro {
    font-size: 35px;
    line-height: 40px;
    font-family: ArialBold;
    margin-bottom: 35px;
  }

  button {
    background-color: #1D7880;
    color: white;
    border-radius: 45px;
    padding: 15px 30px;
    border: 0;
    font-size: 29px;
    font-family: ArialBold;
    width: auto;
    margin: 11px auto 18px;
  }

  &__thanks {
    font-size: 16px;
    font-family: ArialBold;
  }
}

#contact-modal {

  .modal-header {
    border-bottom: 0;
  }

  .modal-content {
    background-color: #51ACB8;
    border-radius: 0;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.45);
  }

  .modal-body {
    padding: 32px 79px 35px;
  }

  .close-button {
    position: absolute;
    right: 34px;
    top: 30px;
    padding: 0;
    color: black;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    font-family: 'ArialBold';
    z-index: 1050;
  }
}
</style>
