<template>
  <article>
    <Slices v-if="prismicDoc" :prismic-doc="prismicDoc" />
    <!-- Spinner while loading -->
    <Spinner v-if="!prismicDoc" />
  </article>
</template>

<script>
import Slices from "@/components/slices/editorial-team/Slices";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "meet-the-team",
  components: {
    Slices,
    Spinner,
  },
  data() {
    return {
      prismicDoc: null,
    };
  },
  async mounted() {
    this.prismicDoc = await this.$prismic.client.getByUID('page', 'meet-the-team',
      this.$route.params.issueSlug
    );
    this.fetchSpotlightNavStatus(this.prismicDoc);
    const pageTitle = this.prismicDoc.data.title ? this.getRichTextAsText(this.prismicDoc.data.title) : ''
    this.trackPageView(pageTitle)
  },
};
</script>

<style scoped></style>
