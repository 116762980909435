<template>
  <section id="homepage-welcome" class="homepage-welcome welcome-with-video">
    <b-container class="homepage-welcome__container" id="content-main">
      <b-row align-h="center">
        <b-col cols="12" xl="6" class="homepage-welcome__container__col">
          <div class="homepage-welcome__container__col__innerbox">
            <div class="video-box">
              <prismic-rich-text :field="slice.primary.guest_title" />
              <div class="vimeo-video" v-if="slice.primary.video_url">
                <prismic-video-player v-if="slice.primary.video_url.video_id" :video="slice.primary.video_url" />
              </div>
              <p v-if="slice.primary.video_content">{{ slice.primary.video_content }}</p>
              <div class="video-box__links" v-if="slice.primary.website_title">
                <div class="video-box__links__link">
                  <div class="video-box__links__link__icon">
                    <img src="../../../assets/img/website-icon.svg" alt="Icon for a website">
                  </div>
                  <a :href="slice.primary.website_link.url" class="video-box__links__link__href"
                     :target="slice.primary.website_link.target"
                  >
                    {{slice.primary.website_title }}
                  </a>
                </div>
                <div class="video-box__links__link" v-if="slice.primary.contact_title">
                  <div class="video-box__links__link__icon">
                    <img src="../../../assets/img/contact-icon.svg" alt="nr">
                  </div>
                  <a  class="video-box__links__link__href"
                      href="#"
                      @click.prevent="$bvModal.show('contact-modal')"

                  >{{
                    slice.primary.contact_title }}</a>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="homepage-welcome__container__nrlogo">
        <img src="@/assets/img/nr-logo.svg" alt="Network Rail logo">
      </div>
    </b-container>
  </section>
</template>

<script>
import PrismicVideoPlayer from '../../PrismicVideoPlayer.vue'

export default {
  name: "HomepageWelcomeWithVideo",
  components: { PrismicVideoPlayer },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  }
};
</script>


<style lang="scss">
.welcome-with-video {
  .video-box {

    h2 {
      margin-bottom: 1rem;
      font-size: 25px;
      line-height: 35px;
    }

    .vimeo-video {
      position: relative;
      margin: 0;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
      font-family: "ArialBold", Fallback, sans-serif;
    }

    &__links {
      display: flex;
      flex-wrap: wrap;

      &__link {
        display: flex;
        align-items: center;
        margin-right: 35px;

        &__icon {
          width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #FF6921;
          border-radius: 100%;
          margin-right: 10px;

          img {
            width: 25px;
            height: auto;
          }
        }

        &__href {
          &:hover {
            color: #1b4c75;
            text-decoration: underline !important;
          }
          @include respond-below(md) {
            font-size: 16px;
          }
        }

        @include respond-below(lg) {
          margin-right: 20px;
          margin-bottom: 1rem;
        }
      }

      @include respond-below(sm) {
        display: block;
      }
    }
  }
}
</style>
