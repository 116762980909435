<template>
  <div id="app">
    <main-header />
      <router-view/>
    <contact-form/>
    <main-footer />
  </div>
</template>

<script>
import store from './store'
import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {ContactForm, MainFooter, MainHeader},
  async mounted() {
    await store.dispatch('fetchAllMenus', this.$prismic)
    if(!store.state.globalContent) {
      await store.dispatch('fetchGlobalContent', {prismic: this.$prismic})
      // this.addBugherd(this.getLabel('bugherd_project_id'))
    }
    // wait for all prismic content to load (3 seconds)
    setTimeout(() => {
      this.setupGlobalModalTrigger()
    }, 2000)

  },
  methods: {
    addBugherd(bugherdProjectId) {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', `https://www.bugherd.com/sidebarv2.js?apikey=${bugherdProjectId}`)
      externalScript.setAttribute('async', 'true')
      document.head.appendChild(externalScript)
    },
    setupGlobalModalTrigger() {
      document.addEventListener('click', (event) => {
        // Check if the target or any of its parents have the specific href
        let target = event.target;
        while (target && target.tagName !== 'BODY') {
          if (target.tagName === 'A' && target.getAttribute('href') === '#contact-modal') {
            event.preventDefault(); // Prevent the default anchor behavior
            this.$bvModal.show('contact-modal'); // Show the modal using BootstrapVue
            break; // Stop the loop if the correct element is found
          }
          target = target.parentNode;
        }
      });
    }
  },
  watch: {
    '$route'(to) {
      document.title = to.meta.title ? `${to.meta.title} - Network Rail` : 'Network Rail'
    }
  }
}
</script>

<style lang="scss">
</style>
