<template>
  <section v-if="slice" class="issuesitems">
    <b-container class="issuesitems__teams">
      <b-row>
        <b-col v-for="(block, index) in slice.items" cols="12" lg="6" :key="index">
          <div class="issuesitems__teams__box">
            <prismic-image v-if="block.person_image.url" :field="block.person_image" />
            <div v-else class="image-bg" />
            <div class="content">
              <h2>{{ block.name }},
                <span v-if="block.person_description[0] && block.person_description[0].text">
                {{ block.person_description[0].text }}
                </span>
              </h2>
              <p>{{ block.short_info }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'Issues-Items',
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
    sliceIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
}
</script>

<style lang="scss">
.issuesitems {
  position: relative;

  @include respond-above(md) {
    .row {
      margin-inline: -60px;
    }
    .row>* {
      padding-inline: 60px;
      margin-bottom: 120px;
    }
  }

  @include respond-below(md) {
    .row>* {
      margin-bottom: 40px;
    }
  }

  &__teams {

    &__box {
      position: relative;
      background: #7F1F78;
      height: 100%;
      overflow: hidden;

      img {
        height: 420px;
        width: 100%;
        object-fit: cover;
        transition: .2s ease-in-out;
        position: relative;
        z-index: 0;
        display: block;
      }

      .image-bg {
        height: 420px;
        width: 100%;
        background: #4A4A4A;
        display: block;
      }

      .content {
        padding: 40px;
        background: #7F1F78;
        position: relative;
        z-index: 1;

        h2 {
          color: #ffffff;
          font-size: 25px;
          line-height: 35px;
          margin-bottom: 5px;
        }

        p {
          color: #ffffff;
          margin: 0;
        }

        @include respond-below(md) {
          padding: 20px;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      @include respond-below(md) {
        height: auto;
      }
    }
  }


}
</style>
