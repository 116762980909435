<template>
  <article class="pageTheme">
    <Slices v-if="prismicDoc" :prismic-doc="prismicDoc" />

    <!-- Spinner while loading -->
    <Spinner v-else />
  </article>
</template>

<script>
import Slices from "@/components/slices/cookies/Slices";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "legal-page",
  components: {
    Slices,
    Spinner,
  },
  data() {
    return {
      prismicDoc: null,
    };
  },
  async mounted() {
    try {
      this.prismicDoc = await this.$prismic.client.getByUID('page', 'legal',
        this.$route.params.issueSlug
      );
      this.fetchSpotlightNavStatus(this.prismicDoc);
      const pageTitle = this.prismicDoc.data.title ? this.getRichTextAsText(this.prismicDoc.data.title) : ''
      this.trackPageView(pageTitle)
    } catch (error) {
      console.error('Error loading page', error)
    }
  },
};
</script>

<style scoped></style>
