<template>
    <section class="story-guestnote">
        <b-container>
            <b-row>
                <b-col cols="12" lg="8">
                    <prismic-rich-text :field="slice.primary.guest_content" />
                </b-col>
                <b-col cols="12" lg="4">
                    <div class="image">
                        <PrismicImage :image="slice.primary.guest_image" :lazyLoad="false" />
                        <p v-if="slice.primary.caption" class="caption">
                            {{ slice.primary.caption }}
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";
export default {
    name: "story-guestnote",
    components: { PrismicImage },
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        }
    }
};
</script>

<style lang="scss">
.story-guestnote {
    margin-bottom: 80px;

  h3 {
    font-size: 40px;
    line-height: 48px;

    @include respond-below(sm) {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 1rem;
    }
  }

    p {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 30px;

        &.caption {
            font-size: 22px;
            line-height: 28px;
            font-family: "ArialBold";
            margin: 0;

            @include respond-below(sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }


        @include respond-below(sm) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
        }
    }

    .image {
        margin: auto;
        max-width: 350px;

        img {
            width: 280px;
            height: 280px;
            object-fit: cover;
            border-radius: 100%;
            margin-bottom: 1rem;
        }

        @include respond-below(md) {
            margin-bottom: 30px;
            max-width: 100%;
            text-align: center;
        }
    }

    @include respond-below(md) {
        .row {
            flex-direction: column-reverse;
        }
    }

    @include respond-below(sm) {
        margin-bottom: 40px;
    }
}
</style>
