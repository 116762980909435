import { render, staticRenderFns } from "./AccessibilityPage.vue?vue&type=template&id=6ba5387a&scoped=true"
import script from "./AccessibilityPage.vue?vue&type=script&lang=js"
export * from "./AccessibilityPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba5387a",
  null
  
)

export default component.exports