<template>
  <article>
    <Slices v-if="prismicDoc" :prismic-doc="prismicDoc" />
    <!-- {{ prismicDoc }} -->
    <!-- Spinner while loading -->
    <Spinner v-if="!prismicDoc" />
  </article>
</template>

<script>
import Slices from "@/components/slices/issue-news-page/Slices";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "issue-community",
  components: {
    Slices,
    Spinner,
  },
  data() {
    return {
      prismicDoc: null,
    };
  },
  async mounted() {
    // fixing a weird prismic bug where i cant save a page with the slug "news" so i have to use "new" instead
    const pagePath = this.$route.path
    const pathEndsWithNew = pagePath.endsWith('new')
    const uid = this.$route.params.issueSlug + (pathEndsWithNew ? '-regional-new' : '-regional-news')
    this.prismicDoc = await this.$prismic.client.getByUID(
      "page",
      uid
    );
    this.fetchSpotlightNavStatus(this.prismicDoc);
    const pageTitle = this.prismicDoc.data.title ? this.getRichTextAsText(this.prismicDoc.data.title) : ''
    this.trackPageView(pageTitle)

  },
};
</script>

<style scoped></style>
