<template>
  <div>
    <StoryHero
      :subline="prismicDoc.data.subline"
      :bannerHeading="prismicDoc.data.title"
      :bannerImage="prismicDoc.data.main_image"
    />
    <StoryButtons
      v-if="prismicDoc.data.show_buttons"
      :uid="prismicDoc.uid"
      :bannerHeading="prismicDoc.data.title"
      :category="prismicDoc.data.category"
    />
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <StoryTextBlock
        :slice="slice"
        v-if="slice.slice_type === 'text_slice'"
        :key="sliceIndex"
      />
      <StoryVideo 
        v-else-if="slice.slice_type === 'video'"
        :key="sliceIndex" 
        :slice="slice" 
      />
      <StoryPolls
        :slice="slice"
        :uid="prismicDoc.uid"
        v-else-if="slice.slice_type === 'polls_block'"
        :key="sliceIndex"
      />
      <StoryCTA
        v-else-if="slice.slice_type === 'cta_baner'"
        :slice="slice"
        :key="sliceIndex"
      />
      <StoryFooterLinks
        :slice="slice"
        v-else-if="slice.slice_type === 'story_links'"
        :key="sliceIndex"
      />
      <StoryImageCarousel
        :slice="slice"
        v-else-if="slice.slice_type === 'image_carousel'"
        :key="sliceIndex"
      />
      <StoryGuestnote
        :slice="slice"
        v-else-if="slice.slice_type === 'guest_note'"
        :key="sliceIndex"
      />
      <StoryTwoColumns
        :slice="slice"
        v-else-if="slice.slice_type === 'two_columns'"
        :key="sliceIndex"
      />
      <StoryEmojis
        :slice="slice"
        :uid="prismicDoc.uid"
        :bannerHeading="prismicDoc.data.title"
        v-else-if="slice.slice_type === 'emojis_block'"
        :key="sliceIndex"
      />

      <div v-else :key="sliceIndex">
        No component found for this slice with the slice_type {{ slice.slice_type }}
      </div>
    </template>
  </div>
</template>
<script>
import StoryHero from "@/components/slices/story/StoryHero";
import StoryButtons from "@/components/slices/story/StoryButtons";
import StoryTextBlock from "@/components/slices/story/StoryTextBlock";
import StoryPolls from "@/components/slices/story/StoryPolls";
import StoryCTA from "@/components/slices/shared/StoryCta";
import StoryVideo from "@/components/slices/story/StoryVideo";
import StoryFooterLinks from "@/components/slices/shared/StoriesFooterLinks";
import StoryEmojis from "@/components/slices/story/StoryEmojis";
import StoryImageCarousel from "@/components/slices/story/StoryImageCarousel";
import StoryGuestnote from "@/components/slices/story/StoryGuestnote";
import StoryTwoColumns from "@/components/slices/story/StoryTwoColumns";

export default {
  name: "story-slices",
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    StoryHero,
    StoryButtons,
    StoryTextBlock,
    StoryVideo,
    StoryPolls,
    StoryCTA,
    StoryEmojis,
    StoryGuestnote,
    StoryTwoColumns,
    StoryImageCarousel,
    StoryFooterLinks
  },
};
</script>

<style lang="scss">
</style>
