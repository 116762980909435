import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import VueLazyload from "vue-lazyload";
import router from './router'
import store from './store'
import PrismicVue from '@prismicio/vue'
import linkResolver from './prismic/link-resolver';
import Helpers from "@/mixins/Helpers";
import {BootstrapVue} from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import {bootstrap} from "vue-gtag";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'animate.css/animate.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.scss'

const cookiebotCid = process.env.VUE_APP_COOKIEBOT_CID;

const script = document.createElement('script');
script.src = `https://consent.cookiebot.com/uc.js`;
// add data-cbid
script.setAttribute('data-cbid', cookiebotCid);
script.async = true;
document.head.appendChild(script);

Vue.config.productionTip = false

const accessToken = process.env.VUE_APP_PRISMIC_API_ACCESS_TOKEN;

Vue.use(VueLazyload)
Vue.mixin(Helpers)
Vue.use(BootstrapVue)
Vue.use(VueScrollTo)
Vue.use(require('vue-cookies'))


Vue.use(PrismicVue, {
    endpoint: process.env.VUE_APP_PRISMIC_API_ENDPOINT,
    linkResolver,
    apiOptions: {accessToken}
});

let ga4Tag
if (window.location.hostname == "spotlight.networkrail.co.uk") {
    ga4Tag = "G-CW3VMHHK20"
} else if (window.location.hostname == "network-rail-spotlight.vercel.app") {
    ga4Tag = "G-WKCP1Y16X8"
} else {
    ga4Tag = "G-XXXXXXXXXX"
}

Vue.use(VueGtag, {
    config: { id: ga4Tag },
    bootstrap: false
});

window.addEventListener('CookiebotOnAccept', function (e) {
    if (Cookiebot.consent.statistics) {
        if (ga4Tag) {
            bootstrap().then((gtag) => {
                console.log('GA4 tag initalised')
            })
        }
    }
}, false);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
